import { useAuth0 } from "../../redux/auth"
import React, { useEffect, useState } from 'react'
import { makeStyles, Link, Button} from '@material-ui/core'
import { Table, TableBody, TableRow, TableHead, useTheme, Box } from '@material-ui/core'
import { LoadingRow, useTableStyles, TableHeadCell, TableDataCell, largeColumnWidth, hugeColumnWidth, mediumColumnWidth } from '../table'
import NoResults from '../NoResults'
import { formatMoney } from '../../util/money'
import moment from 'moment'

const PaymentInfo = (props) => {
    const data = props.data?.paymentData === null || props.data?.paymentData === undefined ? [] : props.data.paymentData
    console.log("props")
    console.log(props)

    const tableClasses = useTableStyles()

    const theme = useTheme()
    const HeadCell = TableHeadCell(theme)
    const DataCell = TableDataCell(theme)


    let body = <div />

    body = (
            <TableBody>
                {data?.txRecords !== undefined && data?.txRecords.map((txr) => (
                    <TableRow 
                        hover 
                        key={txr.id} 
                        className={tableClasses.dataRow}>
                        <DataCell>{moment(txr.time).format('L LT')}</DataCell>
                        <DataCell>{txr.id}</DataCell>
                        <DataCell>{txr.type}</DataCell>
                        <DataCell>{txr.memo}</DataCell>
                        <DataCell align='right'><strong>{formatMoney(txr.amtC)}</strong></DataCell>
                        <DataCell align='right' style={{paddingRight: theme.spacing(2)}}>
                            <strong>{txr.balC ? formatMoney(txr.balC) : ''}</strong>
                        </DataCell>
                    </TableRow>
                ))}
            </TableBody>
        )


    return (
        <Box className={tableClasses.tableAndNoResultsContainer}>
            <Table className={tableClasses.table} stickyHeader>
                <TableHead>
                    <HeadCell style={{width: hugeColumnWidth}}>Date</HeadCell>
                    <HeadCell style={{width: largeColumnWidth}}>ID</HeadCell>
                    <HeadCell style={{width: mediumColumnWidth}}>Type</HeadCell>
                    <HeadCell style={{width: '100%'}}>Memo</HeadCell>
                    <HeadCell style={{width: mediumColumnWidth}} align='right'>Amount</HeadCell>
                    <HeadCell style={{width: mediumColumnWidth, paddingRight: theme.spacing(2)}} align='right'>Balance</HeadCell>
                </TableHead>
                {body}
            </Table>
            {(data?.txRecords === null || data?.txRecords === undefined) && <NoResults message='No transactions found' />}
        </Box>
    )
}

export default PaymentInfo