import { useAuth0 } from "../redux/auth"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, makeStyles, Snackbar, SnackbarContent, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SCORECHECK_INSUFFICENT_FUNDS, SCORECHECK_OK, SCORECHECK_OTHER_ERROR, SCORECHECK_RATE_LIMITED } from '../redux/backend/api-client';
import { humanizedTimeSince } from '../redux/backend/proxies';
import { actConfirmIpScorePurchase, actRequestIpScore, actResetScoreCheck, actSetIpScoreCheckResult } from '../redux/backend/scorecheck';
import { formatMoney } from '../util/money';
import { useDialogStyles } from './dialog';
import '../../src/App.css'

const statusAvailable = "available";

export const ConfirmDialog = (props) => {
    const classes = useDialogStyles();

    const handleClose = () => props.dispatch(actResetScoreCheck());

    const { getAccessTokenSilently } = useAuth0();
    const handleConfirm = () => props.dispatch(actConfirmIpScorePurchase(getAccessTokenSilently));

    const [price, setPrice] = useState(0);
    if (props.scoreCheckInfo && price !== props.scoreCheckInfo.priceC) {
        setPrice(props.scoreCheckInfo.priceC);
    }

    return (
        <Dialog open={Boolean(props.scoreCheckInfo)} onClose={handleClose} classes={{ paper: classes.dialog }}>
            <DialogTitle>Confirm Score Purchase</DialogTitle>
            <DialogContent className={classes.content}>
                An IP score check contains IP scores from up to three sources as well as IP address warnings (proxy, VPN, Tor etc.)<br /><br />
                Your account will be charged a fee of <strong>{formatMoney(price)}</strong>.
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button variant="contained" color="primary" disableElevation onClick={handleConfirm}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

const IPScoreSnackBarBase = (props) => {
    const [scoreCheckResult, setScoreCheckResult] = useState("");
    if (props.scoreCheckResult && scoreCheckResult !== props.scoreCheckResult) {
        setScoreCheckResult(props.scoreCheckResult);
    }

    const handleCloseSnackbar = () => {
        props.dispatch(actSetIpScoreCheckResult(null));
    };

    return (
        <Snackbar open={props.scoreCheckResult} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <SnackbarContent
                message={mapScoreCheckResultToMessage(scoreCheckResult)}
                action={<Button size='small' color='secondary' onClick={handleCloseSnackbar}>Dismiss</Button>} />
        </Snackbar>
    );
};

const mapStateToProps = (state) => ({
    scoreCheckInfo: state.scoreCheckInfo,
    scoreCheckResult: state.scoreCheckResult,
});

export const IPScoreSnackBar = connect(mapStateToProps)(IPScoreSnackBarBase);

const useStyles = makeStyles((theme) => ({
    warnings: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    warningItem: {
        border: '1px solid',
        borderRadius: 5,
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.5),
        textTransform: 'uppercase',
        fontSize: 12,
        cursor: 'pointer',
    },
    update: {
        marginTop: theme.spacing(2)
    },
    scoreValue: {
        textAlign: 'right',
        display: 'block',
        float: 'right',
        marginRight: theme.spacing(2),
    }
}));

const IPScoreSection = (props) => {

    const classes = useStyles();

    const check = props.scoreCheck;
    const hasWarnings = check?.warnings && (check.warnings.proxy || check.warnings.tor || check.warnings.vpn || check.warnings.hosting || check.warnings.abuse);

    const Score = (props) => <><span>Score {props.id}:</span> <strong className={classes.scoreValue}>{Math.round(props.score * 100.0) / 100.0}</strong></>;
    const Warning = (props) => <Tooltip title={props.tooltip}><span className={classes.warningItem}>{props.children}</span></Tooltip>;

    const { getAccessTokenSilently } = useAuth0();

    const requestScore = () => {
        props.dispatch(actRequestIpScore(getAccessTokenSilently, props.proxyId));
    };

    let content;
    if (check && check.status === "success") {
        content = (
            <>
                <div className='web-score-section'>
                    <Box>
                        <Box>{check.score.status1 === statusAvailable && <Score id="G" score={check.score.score1} />}</Box>
                        <Box>{check.score.status2 === statusAvailable && <Score id="I" score={check.score.score2} />}</Box>
                        <Box>{check.score.status3 === statusAvailable && <Score id="M" score={check.score.score3} />}</Box>
                        {hasWarnings &&
                            <Box className={classes.warnings}>
                                {check.warnings.proxy && <Warning tooltip="This IP may be detected as a proxy">Proxy</Warning>}
                                {check.warnings.tor && <Warning tooltip="This IP may be a Tor node">Tor</Warning>}
                                {check.warnings.vpn && <Warning tooltip="This IP may be a VPN">VPN</Warning>}
                                {check.warnings.hosting && <Warning tooltip="This IP may come from a hosting provider">Hosting</Warning>}
                                {check.warnings.abuse && <Warning tooltip="This IP may be present on abuse lists">Abuse</Warning>}
                            </Box>
                        }
                        {!hasWarnings &&
                            <Box className={classes.warnings}>No warnings</Box>
                        }
                        <Box>{humanizedTimeSince(check.time, "Updated ", " ago")}</Box>
                        <Box className={classes.update}>
                            <Link href="#" onClick={requestScore}>Update now</Link>
                        </Box>
                    </Box>
                </div>
                <div className='mobile-score-section'>
                    <Box>
                        <div className='mobile-score-item first'>{check.score.status1 === statusAvailable && <Score id="G" score={check.score.score1} />}</div>
                        <div className='mobile-score-item'>{check.score.status2 === statusAvailable && <Score id="I" score={check.score.score2} />}</div>
                        <div className='mobile-score-item last'>{check.score.status3 === statusAvailable && <Score id="M" score={check.score.score3} />}</div>
                        {hasWarnings &&
                            <div className='ip-score-btns-container'>
                                {check.warnings.proxy && <div className='proxy-btn'>Proxy</div>}
                                {check.warnings.abuse && <div className='abuse-btn'>Abuse</div>}
                            </div>
                        }
                        {!hasWarnings &&
                            <div className='no-warnings'>No warnings</div>
                        }
                        {/* <Box>{humanizedTimeSince(check.time, "Updated ", " ago")}</Box> */}
                        <div className='update-now-btn'>
                            <Link href="#" onClick={requestScore}>Update now</Link>
                        </div>
                    </Box>
                </div>

            </>
        );
    } else {
        content = (
            <>
                <Box>Not requested yet</Box>
                <Box className={classes.update}><Link href="#" onClick={requestScore}>Request now</Link></Box>
            </>
        );
    }
    return (
        <>
            <ConfirmDialog {...props} />
            {content}
        </>
    );
};

const mapScoreCheckResultToMessage = (res) => {
    switch (res) {
        case SCORECHECK_OK:
            return "IP Score information updated";
        case SCORECHECK_INSUFFICENT_FUNDS:
            return "Insufficient balance, add funds and try again";
        case SCORECHECK_RATE_LIMITED:
            return "You are making too many requests, try again later";
        default:
            return "An error happened, try again later";
    }
};

export default connect(mapStateToProps)(IPScoreSection);
