import React, { useState, useEffect } from 'react';
import { actCloseAddFunds, actCreateCharge, actResetNewChargeCode } from '../../redux/backend/add-funds';
import { OutlinedInput, InputAdornment, makeStyles, Link, FormControl, InputLabel, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { useAuth0 } from "../../redux/auth"
import { openTermsAndConditions } from '../../util/util';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { useDialogStyles } from '../dialog';
import ChoseCurrency from './choseCurrency.js';
import { Redirect } from 'react-router-dom';
import './style.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    width: 340,
    paddingBottom: theme.spacing(12),
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: '#121212',
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  disclaimer: {
    color: '#6C7380',
    fontSize: 12,
    display: 'block',
  },
  controlBlock: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  control: {
  },
  controlSpacer: {
    flexGrow: 1,
  },
  addFundsButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  select: {
    fontWeight: 700,
  },
}));

const MyFundsMobile = (props) => {
  const classes = useStyles();


  const close = () => {
    props.dispatch(actCloseAddFunds());
  };

  const [amtC, setAmtC] = useState(500);
  const [currencyType, setCurrencyType] = useState('ltc');

  const [buttonState, setButtonState] = useState(false);

  const changeAmtC = (ev) => {
    if (ev.target.value < 2) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
    currencyType === 'trc20usdt' ? setAmtC(ev.target.value * 50) : setAmtC(ev.target.value * 100);
  };

  const changeCurrencyType = (ev) => {
    if (ev?.target?.value) {
      setCurrencyType(ev?.target?.value);
    } else {
      setCurrencyType(ev);
    };
  };

  const { getAccessTokenSilently } = useAuth0();

  const addFunds = () => {
    close();
    props.dispatch(actCreateCharge(getAccessTokenSilently,
      currencyType === 'trc20usdt' ? amtC / 50 : amtC / 100,
      props.account.userID,
      props.account.email,
      currencyType));
  };

  const dispatch = props.dispatch
  const newChargeCode = props.newChargeCode
  const now = new Date()
 

  const invoiceItem = {
    newChargeCode: props.newChargeCode,
    currencyType: props.currencyType,
    currencyAddress: props.currencyAddress,
    currencyAmount: props.currencyAmount,
    amountUSD: props.amountUSD,
    expire: now.getTime() / 1000 + 60 * 60 * 6,
  }

  useEffect(() => {
    if (newChargeCode) {
      dispatch(actResetNewChargeCode())
      sessionStorage.clear()
      // localStorage.removeItem(JSON.stringify(invoiceItem));
      const url = window.location.origin + '/charges/' + newChargeCode
      sessionStorage.setItem(newChargeCode, JSON.stringify(invoiceItem))
      window.location = url
    }
  }, [newChargeCode, dispatch])

  const commonSelectProps = {
    className: classes.select,
  };

  const commonFormControlProps = {
    className: classes.control,
    variant: 'outlined',
  };

  const dialogClasses = useDialogStyles();
  useEffect(() => {
    dispatch(getActiveItem('Add Funds'));
  }, [dispatch]);

  return (
    <div className='add-funds-mobile-page'>
      <div className='add-funds-content'>
        <div className='amount-title'>About</div>
        <FormControl {...commonFormControlProps} className='amount-mobile-input'>
          <div className='about-label'><InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel></div>
          <OutlinedInput
            required
            id="outlined-adornment-amount"
            onChange={changeAmtC}
            style={{ width: 140 }}
            // {...commonSelectProps}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            // value={amtC / 100}
            value={currencyType === 'trc20usdt' ? amtC / 50 : amtC / 100}
          />
        </FormControl>
        <ChoseCurrency onChange={(item) => changeCurrencyType(item)} />
        <div className='first-paragraph'>
          Once you click 'Add Funds' below, a new tab will be opened with our payment provider showing the cryptocurrency wallet ID and a QR code for you to use.
        </div>
        <div className='second-paragraph'>
          Minimum amount is {currencyType === 'ltc' || currencyType === 'btc' ? 5 : 10}$
        </div>
        {currencyType == 'trc20usdt' && 
            <div className='second-paragraph'>
                If you will send less than 10$ , transfer commission will be on deducted from your sum and it can be near 3$.
            </div>
        }
        <div className='third-paragraph'>
          Please allow pop-ups, if your browser asks you.
        </div>
        <div className='fourth-paragraph'>
          After the crypto transaction is completed, the funds will appear on your balance within few minutes.
        </div>
        <div className='fifth-paragraph'>
          Please note that balance cannot be refunded to currency. <br />
          See our <Link href="#" onClick={openTermsAndConditions}>Terms and Conditions</Link> for more details.
        </div>
      </div>
      <div className='add-funds-btn'>
        <div className='add-funds-btn-container'>
          {/* <div className='add-funds-btn' onClick={addFunds}>Add funds</div> */}
          <Button
            disabled={
              (currencyType === 'ltc' || currencyType === 'btc') && (amtC / 100 < 5) ? true :
                currencyType === 'trc20usdt' && (amtC / 50 < 10) ? true : false}
            className='add-funds-btn'
            variant='contained'
            color='primary'
            disableElevation
            onClick={addFunds}
          >
            Add Funds
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  active: state.addFunds.modelOpen,
  newChargeCode: state.addFunds.newChargeCode,
  canDeposit: state.account?.permissions.includes('DEPOSIT'),
  currencyType: state.addFunds.currencyType,
  currencyAddress: state.addFunds.currencyAddress,
  currencyAmount: state.addFunds.currencyAmount,
  currencyUSD: state.addFunds.amountUSD
});

export default connect(mapStateToProps)(MyFundsMobile);
