import React from 'react';
import { useAuth0 } from "../../redux/auth"
import { useState } from 'react';
import { connect } from 'react-redux';
import { actConfirmRenew } from '../../redux/backend/renew';
import { actRequestIpScore } from '../../redux/backend/scorecheck';
import { actRequestNewSpeedCheck } from '../../redux/backend/speedcheck';
import ButtonWithTooltip from '../ButtonWithTooltip';
import ConfirmRefundDialog from '../my-proxies/ConfirmRefundDialog';

const PopupInfo = (props) => {

  const { getAccessTokenSilently } = useAuth0();
  const requestScore = () => {
    props.dispatch(actRequestIpScore(getAccessTokenSilently, props?.selected?.id));
  };

  const testSpeed = () => {
    props.dispatch(actRequestNewSpeedCheck(getAccessTokenSilently, props?.selected?.id));
  };

  const handleRenew = () => {
    props.dispatch(actConfirmRenew(props?.lease));
  };

  const [confirmRefundOpen, setConfirmRefundOpen] = useState(false);

  const refund = () => {
    setConfirmRefundOpen(true);
  };

  console.log(props?.lease, 'props?.lease')

  return (
    <div className='popup-cont'>
      {/* <div onClick={requestScore}>Update Score</div> */}
      {/* <div onClick={testSpeed}>Test Speed</div> */}
      {props?.lease?.renewable ? <div onClick={handleRenew} style={{color: 'blue', textDecoration: 'underline'}}>Renew</div> : <div style={{color: '#a7a4a4'}}>Renew</div>}
      {props?.lease?.stats?.refundable ? <>
        <ConfirmRefundDialog open={confirmRefundOpen} onClose={() => setConfirmRefundOpen(false)} isMobile={true} />
        <div onClick={refund} style={{color: 'blue', textDecoration: 'underline'}}>Refund</div>
      </> : <div style={{color: '#a7a4a4'}}>Refund</div>
      }
      {/* {!props?.lease.stats?.refundable &&
        <ButtonWithTooltip
          tooltipText="You can only refund in the first 4 hours of the lease, if the proxy goes offline"
          variant='outlined' color='primary' disabled={true}>
          Refund
        </ButtonWithTooltip>
      } */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selected: state.proxies.selected,
  lease: state.activeLeases.selected,
});

export default connect(mapStateToProps)(PopupInfo);