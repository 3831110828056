import { useAuth0 } from "../../redux/auth"
import React, { useEffect, useState } from 'react'
import { makeStyles, Link, Button} from '@material-ui/core'
import { Table, TableBody, TableRow, TableHead, useTheme, Box , TableCell} from '@material-ui/core'
import { LoadingRow, useTableStyles, TableHeadCell, TableDataCell, largeColumnWidth, hugeColumnWidth, mediumColumnWidth } from '../table'
import NoResults from '../NoResults'
import moment from 'moment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles((theme) => ({
  nameCell: {
      fontWeight: 'bold',
  },
  table: {
    maxWidth: '100%',
    tableLayout: 'fixed',
  },
}))

const TransactionInfo = (props) => {
  const classes = useStyles()
  
  const tableClasses = useTableStyles()

  const theme = useTheme()
  const HeadCell = TableHeadCell(theme)
  const DataCell = TableDataCell(theme)

  // console.log('PROPS')
  // console.log(props)
  let transactionData = props?.data?.transactionData ? props?.data?.transactionData : []
  // console.log('transactionData')
  // console.log(transactionData)


  function Row(props) {
    const rec = props.row
    const [open, setOpen] = React.useState(false)
    
    return (    
            <React.Fragment>
                    <TableRow 
                        hover 
                        key={rec.uid} 
                        className={tableClasses.dataRow}>
                        <TableCell style={{width: '20px'}}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                          >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <DataCell>{moment.unix(rec.time_created/1000).format('YYYY-MM-DD HH:mm:ss')}</DataCell>
                        <DataCell>{moment.unix(rec.time_confirmed/1000).format('YYYY-MM-DD HH:mm:ss')}</DataCell>
                        <DataCell>{rec.type}</DataCell>
                        <DataCell>{rec.txid}</DataCell>
                        <DataCell>{rec.uid}</DataCell>
                        <DataCell align='right' style={{paddingRight: theme.spacing(2)}}>{rec.address}</DataCell>
                        <DataCell align='right'><strong>{rec.amount}</strong></DataCell>                        
                    </TableRow>
                    <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Raw Data</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                  <TableRow key={rec.uid+rec.time_created}>
                                    <TableCell  colSpan={8}>
                                      <p>{JSON.stringify(rec, false, "\t")}</p>
                                    </TableCell>
                                  </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
            </React.Fragment>
    )
  }

  return (
    <Box className={tableClasses.tableAndNoResultsContainer}>
        <Table className={classes.table} stickyHeader>
            <TableHead>
              <HeadCell style={{width:10}}></HeadCell>
                <HeadCell style={{width: hugeColumnWidth}}>TimeCreated</HeadCell>
                <HeadCell style={{width: 200}}>TimeConfirmed</HeadCell>
                <HeadCell style={{width: mediumColumnWidth}}>Type</HeadCell>
                <HeadCell style={{width: 200}}>TaxID</HeadCell>
                <HeadCell style={{width: 200}}>InvoiceID</HeadCell>
                <HeadCell style={{width: 200}}>Wallet</HeadCell>
                <HeadCell style={{width: mediumColumnWidth}} align='right'>Amount</HeadCell>
            </TableHead>
            <TableBody>
            {transactionData.map((rec) => (
              <Row key={rec.uid} row={rec} />
            ))}
            </TableBody>
        </Table>
        {(transactionData.length === 0) && <NoResults message='No transactions found' />}
    </Box>
)
}

export default TransactionInfo
