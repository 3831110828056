import { useAuth0 } from "../../redux/auth"
import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Link, Button} from '@material-ui/core'
import CopyButton, { CopySnackbar } from '../my-proxies/CopyButton'
import humanFormat from 'human-format'
import { humanizedActivity, humanizedAgeSinceFirstSeen } from '../../redux/backend/proxies'
import { changedFields, infoChanged } from '../my-proxies/util'
import { actRefundLease } from '../../redux/backend/admin'
import { actUpdateUserInfo } from '../../redux/backend/admin'
import { min } from 'moment';


const useStyles = makeStyles((theme) => ({
  nameCell: {
      fontWeight: 'bold',
  },
  overTraffic: {
      color: 'red',
      fontWeight: 'bold',
  },
  field: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      maxWidth: '100%',
      overflowWrap: 'break-word',
  },
  refunded: {
    color: 'red',
    fontWeight: 'bold',
  },
}))

const CopyConnectionStringLink = (props) => {
    // console.log('PROPS')
    // console.log(props)

    const socks = props?.lease?.socks
    const connectionString = 'socks5h://' + socks?.user + ':' + socks?.pwd + '@' + socks?.ip + ':' + socks?.port
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const copyConnectionString = () => {
        navigator.clipboard.writeText(connectionString)
        setSnackbarOpen(true)
    }

    return (
        <>
            <Link href='#' onClick={copyConnectionString}>Copy connection string</Link>
            <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
        </>
    )
}

const formatTimeLeft = (date) => {
    const diffMs = date - Date.now()
    const minutes = Math.max(0, Math.floor(diffMs / (60 * 1000) % 60))
    const hours = Math.floor(diffMs / (60 * 60 * 1000))

    let result = minutes + "m"
    if (hours > 0) {
        result = hours + "h " + result
    }

    if (result === "0m") {
        result = "now"
    }

    return result
}

function ChangedProperties(lease) {
  if (lease.lease ==  undefined || lease.lease == null || lease.lease ==  'undefined') {
    return <div><b>NO</b></div>;
  }
  return changedFields(lease.lease).map(changedField =>
      <div key={changedField[0]}>
          <span >{changedField[0]}:</span>
          <span>{changedField[1]}</span>
      </div>
  )
}

const LeaseInfo = (props) => {
   const classes = useStyles()
   // console.log(props)
   const lease = props?.lease?.leaseInfo
   // console.log(lease)
   const totalTraffic = (lease?.stats?.outBytes + lease?.stats?.inBytes)/1024/1000
   const date = new Date(lease?.until)
   const overUsed = date.getHours()<=18?true:false

   const { getAccessTokenSilently } = useAuth0()

   const handleRefundLease = () => {
       props.lease.dispatch(actRefundLease(getAccessTokenSilently, lease?.id))
   }

   const amountC = lease?.purchasePriceC
   const userId = lease?.user.replace('urn:user:', '')

   const handleUpdateBalance = () => {
       const amountCInt = parseInt(amountC)
       if (amountCInt) {
           props.lease.dispatch(actUpdateUserInfo(getAccessTokenSilently, userId, { balanceDeltaC: amountCInt }))
       }
   }

   let refundDisble = false

   lease?.ended == true ? refundDisble = true : refundDisble = false

   // console.log('REFUND : ' +refundDisble)

   const dateFormat = (date) => {
      let d = new Date(date)
      let year = d.getFullYear();
      let month = d.getMonth()+1;
      let dt = d.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      let hours = d.getHours()
      let min = d.getMinutes()

      return  year+'-'+month+'-'+dt+' '+hours+':'+min
   }

   const getEndedHours = (date) => {
    // console.log('DATE1' + new Date().toJSON())
    // console.log('DATE2' + date.toJSON())
    let diff = Math.round(Math.abs(new Date(new Date().toJSON()).getTime() - date.getTime()) / 3600000);

    return diff
   }

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
          <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
            <TableCell component="th" scope="row" colSpan={4} className={classes.nameCell}>
            {props.lease?.refundStatus &&
                <span className={classes.refunded}>
                Lease {props.lease?.refundLease} refunded
                </span>
              }
              {props.lease?.refundStatus == false &&
                  <span className={classes.refunded}>
                  Lease {props.lease?.refundLease} not refunded
                  </span>
                }
            </TableCell>
          </TableRow>
          <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={classes.nameCell} colSpan={2}>Static</TableCell>
                <TableCell align="right" colSpan={2}>{lease?.static?'YES':'NO'}</TableCell>
          </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={classes.nameCell}>Lease ID</TableCell>
                <TableCell align="right">{lease?.id}</TableCell>

                <TableCell component="th" scope="row" className={overUsed ? classes.overTraffic: classes.nameCell}>{lease?.refundAmountC != null && lease?.refundAmountC > 0 ? 'Refunded' : 'Expire In'}</TableCell>
                <TableCell align="right" className={(overUsed || lease?.ended==true) ? classes.overTraffic: classes.nameCell}>
                  {lease?.ended==true && (lease?.refundAmountC == null || lease?.refundAmountC == 0) ? 
                    <span>ENDED : {getEndedHours(new Date(lease?.until))}h ago</span>
                  :lease?.ended==true && (lease?.refundAmountC != null || lease?.refundAmountC > 0) ?
                    <span>{dateFormat(lease?.refundTime)}</span>
                  :<span>{formatTimeLeft(new Date(lease?.until))}</span>
                  }
                </TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={classes.nameCell}>Activity</TableCell>
                <TableCell align="right">{humanizedActivity(lease?.currentInfo?.actv, lease?.currentInfo?.probeActv)}</TableCell>

                <TableCell component="th" scope="row" className={classes.nameCell}>Uptime</TableCell>
                <TableCell align="right">{humanizedAgeSinceFirstSeen(lease?.currentInfo?.firstSeen)}</TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={totalTraffic > 100 ? classes.overTraffic :classes.nameCell}>Triffic OUT</TableCell>
                <TableCell align="right" className={totalTraffic >= 100 ? classes.overTraffic :classes.nameCell}>{lease?.stats?.outBytes ? humanFormat.bytes(lease?.stats?.outBytes, {decimals: 0}): 0}</TableCell>

                <TableCell component="th" scope="row" className={totalTraffic > 100 ? classes.overTraffic :classes.nameCell}>Triffic IN</TableCell>
                <TableCell align="right" className={totalTraffic >= 100 ? classes.overTraffic :classes.nameCell}>{lease?.stats?.inBytes ? humanFormat.bytes(lease?.stats?.inBytes, {decimals: 0}): 0}</TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={classes.nameCell}>Total Requests</TableCell>
                <TableCell align="right">{lease?.stats?.reqs ? humanFormat(lease?.stats?.reqs, {decimals: 0}) : 0}</TableCell>

                <TableCell component="th" scope="row" className={classes.nameCell}>Failed</TableCell>
                <TableCell align="right">{lease?.stats?.failPct ? Math.floor(lease?.stats?.failPct) + "%" : 0}</TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={classes.nameCell}>Shared</TableCell>
                <TableCell align="right">{lease?.exc ? "Private" : (lease?.leases ? lease?.leases?.count : "Shared")}</TableCell>

                <TableCell component="th" scope="row" className={classes.nameCell}>Autorenew</TableCell>
                <TableCell align="right">{lease?.autoRenew? 'ON' : 'OFF'}</TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" colSpan={2} className={classes.nameCell}>Properties Changed</TableCell>
                <TableCell align="right" colSpan={2}>
                  <ChangedProperties lease={lease}/>
                </TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" className={classes.nameCell}>User ID</TableCell>
                <TableCell align="right">{userId}</TableCell>

                <TableCell component="th" scope="row" className={classes.nameCell}>Connection str</TableCell>
                <TableCell align="right"><CopyConnectionStringLink lease={lease}/></TableCell>
              </TableRow>
              <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                <TableCell component="th" scope="row" colSpan={2} className={classes.nameCell}>Refund</TableCell>
                <TableCell align="right" colSpan={2}>
                {refundDisble === true &&
                <Button
                    className={classes.rowItem}
                    variant='contained'
                    color='primary'
                    onClick={handleUpdateBalance}>
                        Add {amountC/100}$
                </Button>
              }
                <Button
                    className={classes.rowItem}
                    variant='contained'
                    color='primary'
                    onClick={handleRefundLease}
                    disabled={refundDisble}>
                        Refund
                </Button>

                </TableCell>
              </TableRow>

          </TableBody>
      </Table>
    </TableContainer>
    )
}

export default LeaseInfo
