import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, TableHead, TableRow, TableBody, useTheme, makeStyles, Box, TableCell, Tooltip, Switch } from '@material-ui/core'
import { useTableStyles, TableDataCell, TableHeadCell, LoadingRow } from '../table'
import { actLoadActiveLeases, actSetSelectedActiveLease, actSwitchAutoRenew } from '../../redux/backend/active-leases'
import { useAuth0 } from "../../redux/auth"
import ReactCountryFlag from 'react-country-flag'
import { countryNames } from '../../data/countryNames'
import NoResults from '../NoResults'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import WifiIcon from '@material-ui/icons/Wifi'
import SmartTooltip from '../SmartTooltip'
import InfoIcon from '@material-ui/icons/Info'
import { infoChanged } from './util'

const useStyles = makeStyles((theme) => ({
    flag: {
        width: 20,
        position: 'relative',
        bottom: 2,
        marginRight: theme.spacing(1),
    },
    connTypeIcon: {
        height: 18,
        position: 'relative',
        top: 3,
        left: -5,
        marginRight: -3,
        opacity: 0.8,
    },
    showingRowsCell: {
        textAlign: 'center',
        size: 16,
        color: '#3A404D',
    },
    infoChangeIcon: {
        height: 18,
        position: 'relative',
        top: 4,
        fill: theme.palette.grey[500],
    },
}))

const formatTimeLeft = (date) => {
    const diffMs = date - Date.now()
    const minutes = Math.max(0, Math.floor(diffMs / (60 * 1000) % 60))
    const hours = Math.floor(diffMs / (60 * 60 * 1000))

    let result = minutes + "m"
    if (hours > 0) {
        result = hours + "h " + result
    }

    if (result === "0m") {
        result = "now"
    }

    return result
}

const MyProxyTable = (props) => {
    const classes = useTableStyles()
    const extraClasses = useStyles()
    const theme = useTheme()

    const HeadCell = TableHeadCell(theme)
    const DataCell = TableDataCell(theme)

    const { getAccessTokenSilently } = useAuth0()
    const { dispatch, neverLoaded } = props
    useEffect(() => {
        if (neverLoaded) {
            dispatch(actLoadActiveLeases(getAccessTokenSilently))
        }
    }, [neverLoaded, dispatch, getAccessTokenSilently])

    const handleRowClick = (lease) => {
        dispatch(actSetSelectedActiveLease(lease))
    }

    const switchAutoRenew = (lease) => {
        dispatch(actSwitchAutoRenew(getAccessTokenSilently, lease))
    }

    const filteredLeases = filterLeases(props.activeLeases, props.filter)

    let body = <div />
    if (!props.loading) {
         body = filteredLeases.map((al) =>
            <TableRow
                key={al.id}
                className={`${classes.dataRow} test_data_row`}
                hover
                classes={{ selected: classes.selected }}
                selected={props.selected?.id === al.id}
                onClick={() => handleRowClick(al)}
            >
                <DataCell>
                    {al.info.conn === 'cell' ?
                        <PhoneAndroidIcon className={extraClasses.connTypeIcon} /> :
                        <WifiIcon className={extraClasses.connTypeIcon} /> }
                    {al.info.ip}
                </DataCell>
                <DataCell style={{width: 40, paddingLeft: 0, paddingRight: 0, align: 'center'}}>
                    {infoChanged(al) &&
                        <Tooltip title="Some properties of this proxy have changed">
                            <InfoIcon className={extraClasses.infoChangeIcon} />
                        </Tooltip>
                    }
                </DataCell>
                <DataCell>
                    {al.info.loc &&
                        <span>
                            <ReactCountryFlag className={extraClasses.flag} countryCode={al.info.loc.cc} svg />
                            {al.info.loc.cc}
                        </span>
                    }
                </DataCell>
                <DataCell><SmartTooltip>{al.info.loc ? al.info.loc.reg : ""}</SmartTooltip></DataCell>
                <DataCell><SmartTooltip>{al.info.loc ? al.info.loc.city : ""}</SmartTooltip></DataCell>
                <DataCell><SmartTooltip>{al.info.loc ? al.info.loc.zip : ""}</SmartTooltip></DataCell>
                <DataCell><SmartTooltip>{al.info.loc ? al.info.loc.isp : ""}</SmartTooltip></DataCell>
                <DataCell>{al.exc ? "Private" : (al.info.leases ? al.currentInfo.leases.count : "Shared")}</DataCell>
                <DataCell>{formatTimeLeft(new Date(al.until))}</DataCell>
                <DataCell>
                    <Switch size="small" checked={al.autoRenew} onChange={() => switchAutoRenew(al)} disabled={al.static?true:false}/>
                </DataCell>
            </TableRow>
        )
    } else {
        body = <LoadingRow />
    }

    return (
        <Box className={classes.tableAndNoResultsContainer}>
            <Table stickyHeader className={classes.table}>
                <TableHead>
                    <TableRow>
                        <HeadCell style={{width: 165}}>IP Address</HeadCell>
                        <HeadCell style={{width: 40, paddingLeft: 0, paddingRight: 0}} />
                        <HeadCell style={{width: '15%'}}>Country</HeadCell>
                        <HeadCell style={{width: '20%'}}>Region</HeadCell>
                        <HeadCell style={{width: '20%'}}>City</HeadCell>
                        <HeadCell style={{width: '15%'}}>ZIP</HeadCell>
                        <HeadCell style={{width: '30%'}}>ISP</HeadCell>
                        <HeadCell style={{width: 80}}>Shared</HeadCell>
                        <HeadCell style={{width: 90}}>Expire</HeadCell>
                        <HeadCell style={{width: 80, paddingRight: 0}}>Renew</HeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {body}
                    {(props.filter) &&
                    <TableRow>
                        <TableCell className={extraClasses.showingRowsCell} colSpan={9}>Showing {filteredLeases.length} out of {props.activeLeases.length}</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            {(!props.loading && props.activeLeases?.length === 0) && <NoResults message='No active proxies found' />}
        </Box>
    )
}

const filterLeases = (leases, filter) => {
    if (filter === null || filter === "") {
        return leases
    }

    const filtered = []

    const f = filter.toLowerCase()

    for (const lease of leases) {
        if (lease.info.ip.toLowerCase().includes(f) ||
            lease.info.loc.cc.toLowerCase().includes(f) ||
            countryNames[lease.info.loc.cc].toLowerCase().includes(f) ||
            lease.info.loc.reg?.toLowerCase().includes(f) ||
            lease.info.loc.city?.toLowerCase().includes(f) ||
            lease.info.loc.zip?.toLowerCase().includes(f) ||
            lease.info.loc.isp?.toLowerCase().includes(f) ||
            lease.note?.toLowerCase().includes(f)) {

            filtered.push(lease)
        }
    }

    return filtered
}

const mapStateToProps = (state) => ({
    ...state.activeLeases,
})

export default connect(mapStateToProps)(MyProxyTable)
