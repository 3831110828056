/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ClipLoader from "react-spinners/ClipLoader";
import { formatMoney } from '../../util/money';
import { actRequestIpScore } from '../../redux/backend/scorecheck';
import { useAuth0 } from "../../redux/auth"
import { actLoadExistingSpeedCheck, actRequestNewSpeedCheck } from '../../redux/backend/speedcheck';
import moment from 'moment';
import { useEffect } from 'react';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { actStartLease } from '../../redux/backend/lease';
import { Score, Warning } from '@material-ui/icons';
import { humanizedActivity, humanizedAgeSinceFirstSeen, humanizedTimeSince } from '../../redux/backend/proxies';
import IPScoreSection, { ConfirmDialog } from '../IPScoreSection';
import './style.css';
import SpeedCheckSection from '../SpeedCheckSection';
import { countryNames } from '../../data/countryNames';
import ReactCountryFlag from 'react-country-flag';
import '../../../src/App.css';
import { LeaseTypeSelector } from '../ProxySidebar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ShopId = (props) => {

  const location = useLocation();

  const [value, setValue] = React.useState(0);

  const shopItemBlockRef = useRef()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activeRadioBtn, setActiveRadioBtn] = useState('private');
  const { getAccessTokenSilently } = useAuth0();
  const check = props.speedCheck;

  const requestScore = () => {
    props.dispatch(actRequestIpScore(getAccessTokenSilently, props?.selected?.id));
  };

  useEffect(() => {
    props.dispatch(getActiveItem('Proxy Default'));
  }, [props]);

  const handleBuyClick = () => {
    if (props.onBuy) {
      props.onBuy();
    }
    props.dispatch(actStartLease(props.selected));
    console.log(props.selected, 'props.selected');
  };

  console.log(props.selected, 'props.selected');

  const statusAvailable = "available";

  const hasWarnings = check?.warnings && (check.warnings.proxy || check.warnings.tor || check.warnings.vpn || check.warnings.hosting || check.warnings.abuse);

  let content;
  if (check && check.status === "success") {
    content = (
      <>
        <Box>
          <Box>{check.score.status1 === statusAvailable && <Score id="G" score={check.score.score1} />}</Box>
          <Box>{check.score.status2 === statusAvailable && <Score id="I" score={check.score.score2} />}</Box>
          <Box>{check.score.status3 === statusAvailable && <Score id="M" score={check.score.score3} />}</Box>
          {hasWarnings &&
            <div className='ip-score-btns-container'>
              {check.warnings.proxy && <div className='proxy-btn'>Proxy</div>}
              {check.warnings.abuse && <div className='abuse-btn'>Abuse</div>}
            </div>
          }
          {!hasWarnings &&
            <div className='no-warnings'>No warnings</div>
          }
          <Box>{humanizedTimeSince(check.time, "Updated ", " ago")}</Box>
          <Box >
            <Link href="#" onClick={requestScore}>Update now</Link>
          </Box>
        </Box>
      </>
    );
  } else {
    content = (
      <>
        <div className='not-requested-yet'>Not requested yet</div>
        <div className='request-now'><Link href="#" onClick={requestScore}>Request now</Link></div>
      </>
    );
  }

  const data = props.selected;

  const leaseTypeLabels = {
    'SHARED': 'Shared',
    'PRIVATE': 'Private',
  };

  return (
    <>
      {
        data ? <div ref={shopItemBlockRef} className='shop-id-page'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Info" {...a11yProps(0)} />
                <Tab label="Blacklists" {...a11yProps(1)} />
                <Tab label="IP Score" {...a11yProps(2)} />
                <Tab label="Speed" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className='first-block'>
                <div className='first-block-item'>
                  <div className='item-label'>Domain</div>
                  <div className='item-content'>{data?.host ? data?.host : data?.ip}</div>
                </div>
                <div className='first-block-item'>
                  <div className='item-label'>Country</div>
                  <div className='item-content'>{countryNames[data?.loc?.cc]}</div>
                </div>
                <div className='first-block-item'>
                  <div className='item-label'>City</div>
                  <div className='item-content'>{data?.loc?.city}</div>
                </div>
                <div className='first-block-item'>
                  <div className='item-label'>State</div>
                  <div className='item-content'>{data?.loc?.reg}</div>
                </div>
                <div className='first-block-item'>
                  <div className='item-label'>Zip</div>
                  <div className='item-content'>{data?.loc?.zip}</div>
                </div>
                <div className='first-block-item'>
                  <div className='item-label'>Isp</div>
                  <div className='item-content'>{data?.loc?.isp}</div>
                </div>
                <div className='first-block-item'>
                  <div className='item-label'>IP Address:</div>
                  <div className='item-content'>{data?.ip}</div>
                </div>
              </div>
              <div className='second-block'>
                <div className='second-block-item'>
                  <div className='item-label'>Uptime</div>
                  <div className='item-content'>{humanizedAgeSinceFirstSeen(data?.firstSeen)}</div>
                </div>
                <div className='second-block-item'>
                  <div className='item-label'>Activity</div>
                  <div className='item-content'>{humanizedActivity(data?.actv, data?.probeActv)}</div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className='blacklist-container'>
                {
                  data?.blacklists?.listIDs.map((item, index) => {
                    return <div className='blacklist-item' key={index}>{item}</div>;
                  })
                }
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className='ip-score-container'>
                <IPScoreSection scoreCheck={data?.scoreCheck} proxyId={data?.id} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className='speed-container'>
                <SpeedCheckSection proxyId={data?.id} speedCheck={data?.speedCheck} />
              </div>
            </TabPanel>
          </Box>
          <div className='actions-section'>
            {/* <div className={activeRadioBtn === 'private' ? 'private-radio-btn active-radio-btn' : 'private-radio-btn'} onClick={() => setActiveRadioBtn('private')}>
              <input type="radio" id="private" name="radios" value="private" defaultChecked />
              <label for="private">
                <div className='radio-btn-item'>
                  <div>Private</div>
                  <div>{data?.priceExcC && formatMoney(data?.priceExcC)}</div>
                </div>
              </label>
            </div>
            <div className={activeRadioBtn === 'shared' ? 'shared-radio-btn active-radio-btn' : 'shared-radio-btn'} onClick={() => setActiveRadioBtn('shared')}>
              <input type="radio" id="shared" name="radios" value="shared" />
              <label for="shared">
                <div className='radio-btn-item'>
                  <div>Shared</div>
                  <div>{formatMoney(data?.priceShrC)}</div>
                </div>
              </label>
            </div> */}
            <Box >
              {props.selected.priceExcC && <LeaseTypeSelector
                name={leaseTypeLabels['PRIVATE']}
                value={props.leaseType}
                dispatch={props.dispatch}
                price={props.selected.priceExcC}
                leaseType='PRIVATE' />}
               {props.selected.priceShrC && <LeaseTypeSelector
                name={leaseTypeLabels['SHARED']}
                value={props.leaseType}
                dispatch={props.dispatch}
                price={props.selected.priceShrC}
                leaseType='SHARED' />}
            </Box>
            <button
              id='lease_button'
              variant='contained'
              disableElevation
              color='primary'
              className='buy-proxy-button'
              onClick={handleBuyClick}
            > Buy Proxy
            </button>
            <div className='more-info'>If 40% or more SOCKS requests via the purchased proxy fail, then its price will be automatically refunded to your balance.</div>
          </div>
        </div> :
          <div className='loader'>
            <ClipLoader
              color='#1589EE'
              size={30}
            />
          </div>
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  selected: state.proxies.selected,
  proxies: state.proxies.proxies,
  lease: state.activeLeases.selected,
  leaseType: state.proxies.leaseType,
});

export default connect(mapStateToProps)(ShopId);
