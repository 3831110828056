import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from "../../redux/auth"
import { makeStyles, Link, Button, TextField, Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Divider } from '@material-ui/core'
import { sidebarContentStyle } from '../ProxySidebar'
import CopyButton, { CopySnackbar } from '../my-proxies/CopyButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { topBorder, inlineIcon } from '../../styles/common'
import PortStats from './PortStats'
import { actUpdatePortWhitelist, actReActivatePort, actRefundPort,
        actResetReActivateResult, actResetRefundResult, actSetOpenBuyConfirmDialog,
        actSetOpenRefundConfirmDialog, actUpdatePortWhitelistToLoginAuth } from '../../redux/backend/ports'
import PortBuyConfirmDialog from './PortBuyConfirmDialog'


const useStyles = makeStyles((theme) => ({
    content: {
        ...sidebarContentStyle(theme),
    },
    info: {
        flexGrow: 1,
        overflowY: 'scroll',
        gridRow: '1 / 4',
    },
    title: {
      color: '#3A404D',
      fontFamily: "SF Pro Medium",
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '24px',
    },
    titleBox: {
      marginTop: 70,
      paddingLeft: 25,
      paddingBottom:15,
    },
    titleSmall: {
      color: '#3A404D',
      fontFamily: "SF Pro Medium",
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: '24px',
    },
    boxSettings : {
      paddingLeft: 25,
      paddingBottom:10,
    },
    sectionSocks: {
        borderWidth: 0,
        borderBottomColor: theme.palette.divider,
        borderStyle: 'solid',
    },
    field: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        maxWidth: '100%',
        overflowWrap: 'break-word',
    },
    field2: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        maxWidth: '100%',
        overflowWrap: 'break-word',
        paddingTop: 5,
    },
    label: {
        marginRight: theme.spacing(1),
    },
    value: {
        fontWeight: 600,
    },
    link: {
        fontSize: 13,
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
    },
    inlineIcon: {
        ...inlineIcon(theme),
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
    },
    textfield : {
    	width: '95%',
    },
    actionButton: {
    	width: '95%',
      marginRight: theme.spacing(1),
    },
    actionButtonBackToLoginAuth: {
    	width: '95%',
      marginRight: theme.spacing(1),
    },
    buttonDiv: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      maxWidth: '100%',
      overflowWrap: 'break-word',
  },
}))

const CopyPortChangeApiLink = (props) => {
    const apiKey = props.data.key
    const portStr = props.data.port != null ? props.data.port : ':port:'

    const linkString = 'https://ports.bigmama.network/port/change/ip/'+portStr+'/'+apiKey

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const copyApiLinkString = () => {
        navigator.clipboard.writeText(linkString)
        setSnackbarOpen(true)
    }

    return (
        <>
            <Link href='#' onClick={copyApiLinkString}>Copy IP rotation link format</Link>
            <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
        </>
    )
}

const PortsSidebar = (props) => {

  const { getAccessTokenSilently } = useAuth0()
  const classes = useStyles()
  const portData = props.ports.selectedPort

  const [passwordHidden, setPasswordHidden] = useState(true)
  const [whitelist, setWhitelist] = useState("")
	const [buttonStatus, setButtonDisbled] = useState(true)
	const [errorWhiteList, setWhiteListError] = useState(false)

  const portWhiteList = whitelist != "" ? whitelist : portData?.access?.whitelist_ips
  const apiPortStr = portData?.traffic?.type == 'unlimit'? portData?.access?.ports[0]:null

  const isRefundable = () => {
    let isRefundable = true

    const usedVolume = Math.floor((portData?.stats?.upload+portData?.stats?.download)/1000000)
    const totalLifeTime = portData?.date_finish-portData?.date_start
    const timePassed = Date.now()-portData?.date_start
    const leftTime = timePassed/totalLifeTime*100

    if (usedVolume > 10) {
      isRefundable = false
    }
    if (leftTime > 50) {
      isRefundable = false
    }

    return isRefundable
  }

  const isReActivatable = () => {
    let isReActivtable = true

    if ((props.account.balanceCents/100) < portData.price) {
        isReActivtable = false
    }

    return isReActivtable
  }


  const hideShowPasswordIcon = passwordHidden ?
      <VisibilityIcon className={classes.inlineIcon} onClick={() => setPasswordHidden(false)} /> :
      <VisibilityOffIcon className={classes.inlineIcon} onClick={() => setPasswordHidden(true)} />

  const startPort = portData?.access?.ports[0]
  const endPort = portData?.access?.ports[portData?.access?.ports.length - 1]

  const formatApiKey = (key) => {
      return key.substring(0, 17)
  }

  const handleWhiteListChange = (e)  => {
		const regexExpIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi

		if (e.target.value == ""){
			setButtonDisbled(true)
			setWhiteListError(false)
		}

		const ipsArr = e.target.value.split(',')
		let checkedCount = 0


		ipsArr.forEach(element => {
			if(!element.match(regexExpIP)) {
				setButtonDisbled(true)
				setWhiteListError(true)
			} else  {
				checkedCount++
			}
		})

		if  (checkedCount == ipsArr.length) {
			setWhiteListError(false)
			setButtonDisbled(false)
		}


		setWhitelist(e.target.value)

	}

  const saveWhitelist = () => {
      const ipsArr = whitelist.split(',')
      const body = {
        package_id: portData.id,
        whitelist_ips: ipsArr
      }

      props.dispatch(actUpdatePortWhitelist(body, getAccessTokenSilently))
      setWhitelist("")
      // props.dispatch(actUpdatePortWhitelist(body, getAccessTokenSilently))
  }

  const switchToLoginAuth = () => {
    props.dispatch(actUpdatePortWhitelistToLoginAuth(portData.id, getAccessTokenSilently))
    // setWhitelist("")
    // props.dispatch(actUpdatePortWhitelist(body, getAccessTokenSilently))
}

  const handleReActivateClick = () => {
    props.dispatch(actSetOpenBuyConfirmDialog(portData, true))
  }

  const handleRefundClick = () => {
    props.dispatch(actSetOpenRefundConfirmDialog(portData.id))
  }

  return (
      <div className={classes.content}>
      {portData !== undefined && portData !== null  &&
          <div className={classes.info}>
                <Box className={classes.titleBox}>
                  <span className={classes.title}>Port Settings</span>
                </Box>
                {portData?.access?.type == 'auth' ?(
                 <Box className={classes.boxSettings}>
                    <Divider />
                    <div className={classes.sectionSocks}>
		                    <div className={classes.field}>
		                        <CopyButton value={portData.access.auth_login} />
		                        <span className={classes.label}>Username:</span>
		                        <span className={classes.value}>{portData.access.auth_login}</span>
		                    </div>
		                    <div className={classes.field}>
		                        <CopyButton value={portData.access.auth_password} />
		                        <span className={classes.label}>Password:</span>
		                        <span className={classes.value}>
                              {passwordHidden ? '•'.repeat(10) : portData.access.auth_password}
                              {hideShowPasswordIcon}
		                        </span>
		                    </div>
                        <div className={classes.field}>
                            <CopyButton value={portData?.access?.port_service_ip} />
                            <span className={classes.label}>SOCKS5 Host:</span>
                            <span className={classes.value}>{portData?.access?.port_service_ip}</span>
                        </div>
		                </div>
                    <div className={classes.field}>
                            <TextField
                             required
                             id="whitelist"
                             label="white list ip(s), format: IP,IP,...IP"
                             variant="outlined"
                             className={classes.textfield}
                             onChange={handleWhiteListChange}
                             error={errorWhiteList}
                             value={portWhiteList}
                           />
                    </div>
                    <div className={classes.field}>
              						 <Button
                                      disabled={buttonStatus}
              	                    	variant='contained'
              	                    	color='primary'
              	                    	onClick={saveWhitelist}
              							className={classes.actionButton}>
        	                    Switch to whitelist auth
        	                </Button>
        					     </div>
                 </Box>
               ):(
                 <Box className={classes.boxSettings}>
                   {/* {portData?.traffic?.type != 'limit' && */}
                  <div className={classes.buttonDiv}>
           						 <Button
           	                    	variant='contained'
           	                    	color='primary'
           	                    	onClick={switchToLoginAuth}
           							className={classes.actionButtonBackToLoginAuth}>
     	                    Swith to login/pass auth
     	                </Button>
     					     </div>
                    {/* } */}
                   <div className={classes.field}>
                     <TextField
                      required
                      id="whitelist"
                      label="white list ip(s), format: IP,IP,...IP"
                      variant="outlined"
                      className={classes.textfield}
                      onChange={handleWhiteListChange}
                      error={errorWhiteList}
                      value={portWhiteList}
                    />
                 </div>
                 <div className={classes.field}>
           						 <Button
           							disabled={buttonStatus}
           	                    	variant='contained'
           	                    	color='primary'
           	                    	onClick={saveWhitelist}
           							className={classes.actionButton}>
     	                    Save
     	                </Button>
     					     </div>
                   <div className={classes.field}>
                       <CopyButton value={portData?.access?.port_service_ip} />
                       <span className={classes.label}>Server Host:</span>
                       <span className={classes.value}>{portData?.access?.port_service_ip}</span>
                   </div>
                 </Box>
               )
                }
                <Box className={classes.boxSettings}>
                  <Divider />
                  <div className={classes.field2}>
                      <span className={classes.label}>Ports Count:</span>
                      <span className={classes.value}>{portData?.access?.port_amount}</span>
                  </div>
                  {portData?.traffic?.type == 'limit' ? (
                    <div className={classes.field2}>
                        <span className={classes.label}>Ports Range:</span>
                        <span className={classes.value}>{startPort} - {endPort}</span>
                    </div>
                ):(
                  <div className={classes.field2}>
                      <span className={classes.label}>Port:</span>
                      <span className={classes.value}>{startPort}</span>
                  </div>
                )}
                  {portData?.switch?.type == 'api' ? (
                    <div>
                    <div className={classes.field}>
                        <CopyButton value={portData?.switch?.api_key} />
                        <span className={classes.label}>API Key:</span>
                          <span className={classes.value}>{formatApiKey(portData?.switch?.api_key)}...</span>
                    </div>
                    <div className={classes.link}>
                        <CopyPortChangeApiLink data={{key:portData?.switch?.api_key, port:apiPortStr}}/>
                    </div>
                    </div>
                  ):(
                    <div className={classes.field2}>
                    <div className={classes.field}>
                        <span className={classes.label}>Rotation Time:</span>
                        <span className={classes.value}>{portData?.switch?.timer}</span>
                    </div>
                    </div>
                  )
                  }
                </Box>
                <Box className={classes.boxSettings}>
                  <Divider />
                  <div className={classes.field2}>
                    <div className={classes.field}>
                        <span className={classes.label}>Country:</span>
                        <span className={classes.value}>{portData?.geo?.country}</span>
                    </div>
                  </div>
                  <div className={classes.field2}>
                    <div className={classes.field}>
                        <span className={classes.label}>State:</span>
                        <span className={classes.value}>{portData?.geo?.state}</span>
                    </div>
                  </div>
                  <div className={classes.field2}>
                    <div className={classes.field}>
                        <span className={classes.label}>City:</span>
                        <span className={classes.value}>{portData?.geo?.city}</span>
                    </div>
                  </div>
                </Box>
                <Box className={classes.boxSettings}>
                  <Divider />
                  <div className={classes.field2}>
                    <PortStats stats={portData?.stats} />
                  </div>
                </Box>
                {isRefundable() &&
                  <Box className={classes.boxSettings}>
                    <Divider />
                    <div className={classes.field2}>
                      <Button
                                 variant='contained'
                                 color='primary'
                                 onClick={handleRefundClick}
                       className={classes.actionButton}>
                         Refund
                     </Button>
                    </div>
                  </Box>
                }
                {portData?.status?.type=='suspend' && isReActivatable() &&
                  <Box className={classes.boxSettings}>
                    <Divider />
                    <div className={classes.field2}>
                      <Button
                                 variant='contained'
                                 color='primary'
                                 onClick={handleReActivateClick}
                       className={classes.actionButton}>
                         ReActivate Port
                     </Button>
                    </div>
                    <PortBuyConfirmDialog />
                  </Box>
                }
          </div>
        }
      </div>
  )
}

const mapStateToProps = (state) => ({
    ...state,
})

export default connect(mapStateToProps)(PortsSidebar)
