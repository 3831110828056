import React from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from "../../redux/auth"
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, makeStyles } from '@material-ui/core';
import { useDialogStyles } from './LeaseFlow';
import { actConfirmLease } from '../../redux/backend/lease';
import { NavLink } from 'react-router-dom';
import './leaseFlow.css';

const useStyles = makeStyles((theme) => ({
    ipWarning: {
        fontWeight: 700,
        color: '#b47b05',
    }
}));

const LeaseConfirm = (props) => {
    const classes = useDialogStyles();
    const extraClasses = useStyles();
    const { getAccessTokenSilently } = useAuth0();

    const handleBuyClick = () => {
        props.dispatch(actConfirmLease(getAccessTokenSilently));
    };

    return (
        <Dialog
            open={true}
            onClose={props.onClose}
            classes={{ paper: classes.dialog }}
            disableBackdropClick={true}
            className='confirm-purchase-dialog'
        >
            <DialogTitle>Confirm Purchase</DialogTitle>
            <DialogContent style={{ maxWidth: 416 }} className={classes.content}>
                <p>
                    The Proxy will be leased to you for 24 hours.
                </p>
                <p>
                    <span className={extraClasses.ipWarning}>
                        Mobile proxies may change their IP addresses many times over that period, which is normal as the mobile device changes its
                        location, switches to Wi-Fi or is reconfigured by the ISP.
                    </span>
                </p>
                <p>
                    A <strong>Private</strong> purchase guarantees that the proxy will only be available to you.
                    A <strong>Shared</strong> purchase allows the proxy to be shared with up to 3 other users.
                </p>
                <p>
                    If the proxy goes offline at any point within the first 4 hours of your lease, you will be eligible to get a full refund to your balance
                    via the Refund button in the <strong>My Proxies</strong> tab or support telegram bot  <a href="https://t.me/bigma_network">telegram</a>, 
                    by providing lease id.
                </p>
                <p>
                After 4 hours or more than 100mb used traffic, a refund is no longer possible even if the proxy goes offline.
                </p>
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
                <Button id='cancel_button' variant='text' color='primary' onClick={props.onClose}>Cancel</Button>
                {window.innerWidth <= 480
                    ? <NavLink to="/shop" className='buy-proxy-confirm-btn' onClick={handleBuyClick}>
                        Buy Proxy
                    </NavLink>
                    : <Button
                        id='confirm_button'
                        variant='contained'
                        disableElevation
                        color='primary'
                        onClick={handleBuyClick}
                    >Buy Proxy</Button>}


            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(LeaseConfirm);
