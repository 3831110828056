import React from 'react';
import { CircularProgress, Link, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { actLoadExistingSpeedCheck, actRequestNewSpeedCheck } from '../redux/backend/speedcheck';
import { useAuth0 } from "../redux/auth"
import moment from 'moment';
import './ShopId/style.css';


const useStyles = makeStyles((theme) => ({
    progress: {
        marginRight: theme.spacing(1),
    },
}));

const SpeedCheckSection = (props) => {
    const classes = useStyles();
    const proxyId = props.proxyId;

    const { getAccessTokenSilently } = useAuth0();

    const getSpeed = () => {
        props.dispatch(actLoadExistingSpeedCheck(getAccessTokenSilently, proxyId));
    };

    const testSpeed = () => {
        props.dispatch(actRequestNewSpeedCheck(getAccessTokenSilently, proxyId));
    };

    const check = props.speedCheck;
    const speedCheckDate = moment(check?.checkTime);
    const speedCheckAge = moment.duration(moment().diff(speedCheckDate));

    const minutesBeforeRetest = 6; // TODO take from config API

    return (
        <>
            <div>
                {!check && <Link href="#" onClick={getSpeed} className='get-connection-speed'>Get connection speed</Link>}
                {check?.loadingExisting && <span>Loading speed...</span>}
                {check?.notFound &&
                    <><span>Speed not tested yet</span> <Link href="#" onClick={testSpeed}>Test Now</Link></>
                }
                {(check?.failed || check?.rejected || (check?.speed === 0)) &&
                    <>
                        <span>Speed could not be tested</span>
                        <div>
                            {(speedCheckAge.asMinutes() >= minutesBeforeRetest) && <Link href="#" onClick={testSpeed}>Retest</Link>}
                        </div>
                    </>
                }
                {check?.checking &&
                    <>
                        <CircularProgress size={15} className={classes.progress} />
                        <span>Testing speed...</span>
                    </>
                }
                {(check?.speed && check?.speed > 0) ?
                    <span className='web-speed'>
                        Speed: {(check.speed / 1000 / 1000).toFixed(2)} Mbps
                        ({speedCheckAge.humanize()} ago)
                        <div>
                            {(speedCheckAge.asMinutes() >= minutesBeforeRetest) && <Link href="#" onClick={testSpeed}>Retest</Link>}
                        </div>
                    </span> : ''}
                {(check?.speed && check?.speed > 0) ? <>
                    <div className='speed-item'>
                        <div className='left-part'>
                            <span className='speed'>Speed</span>
                            <div className='speed-value'>{(check.speed / 1000 / 1000).toFixed(2)} Mbps</div>
                        </div>
                        <div className='right-part'>{speedCheckAge.humanize()} ago</div>
                    </div>
                    <div className='mobile-retest-btn'>
                            {(speedCheckAge.asMinutes() >= minutesBeforeRetest) && <Link href="#" onClick={testSpeed}>Retest</Link>}
                        </div>
                </>
                     : ''}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(SpeedCheckSection);