import React, { useState, useEffect } from 'react'
import { Modal, OutlinedInput,InputAdornment, Fade, makeStyles, Box,
  Select, MenuItem, FormControl, InputLabel, Button, Link, FormControlLabel, Switch} from '@material-ui/core'
import { connect } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { actRequestCreatePort, actSetResetResult, actCloseCreatePortDialog,
  actLoadCountriesList, actGetPortPrice, initialPortCreateState, actLoadPortsList,
  actSetOpenBuyConfirmDialog, actLoadRegionList, actLoadCitiesList } from '../../redux/backend/ports'
import { useAuth0 } from "../../redux/auth"
import { useDialogStyles } from '../dialog'
import { countryNames, continentCodeToNames, continentToCountries } from '../../data/countryNames'
import '../css/FaqPage.css'
import ReactCountryFlag from 'react-country-flag'
import PortBuyConfirmDialog from './PortBuyConfirmDialog'

const useStyles = makeStyles((theme) => ({
    main: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 590,
      heigh: 600,
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
    },
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    content: {
        width: 590,
        paddingBottom: theme.spacing(12),
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        display: 'block',
        marginBottom: theme.spacing(1),
        paddingLeft:32,
        paddingTop: 10,
        fontFamily:'SF Pro Medium',
  		  color: '#3A404D',
        lineHeight: '16px',
    },
    smallTitle: {
        fontSize: 15,
        fontWeight: 500,
        display: 'block',
        marginBottom: theme.spacing(1),
        paddingTop: 24,
        fontFamily:'SF Pro Medium',
  		  color: '#5d626e',
        letterSpacing:0,
        lineHeight: '16px',
    },
    selectBox:{
      paddingLeft:32,
      paddingTop: 5,
      display:'flex',
    },
    periodBox:{
      paddingLeft:32,
      paddingTop: 5,
    },
    periodBox2:{
      display:'flex',
      paddingRight:32,
    },
    typeBox:{
      paddingLeft:32,
      paddingTop: 5,
      paddingRight:32,
      display:'flex',
    },
    priceBox:{
      paddingLeft:32,
      paddingTop: 5,
    },
    bottomBox:{
      paddingLeft:32,
      paddingRight:32,
      display:'flex',
    },
    typeButtonTraffic:{
      boxSizing: 'border-box',
      height: 40,
      width: 295,
      border: '1px solid #4C97FF',
      borderRadius: '4px 0 0 4px',
      backgroundColor: 'rgba(76,151,255,0.07)',
      cursor: 'pointer',
      '&:hover': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
      },
      flexGrow: 1,
      textAlign:'center',
      paddingTop: 10,
      color: '#4C97FF',
      fontFamily: "SF Pro",
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    typeButtonUnlimited:{
      boxSizing: 'border-box',
      height: 40,
      width: 295,
      border: '1px solid #D0D4DB',
      borderRadius: '0 4px 4px 0',
      backgroundColor: '#FFFFFF',
      cursor: 'pointer',
      '&:hover': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
      },
      flexGrow: 2,
      textAlign:'center',
      paddingTop: 10,
      textAlign:'center',
      color: '#525966',
      fontFamily: "SF Pro",
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    periodButtonFirst:{
      boxSizing: 'border-box',
      height: 40,
      width: 159,
      border: '1px solid #D0D4DB',
      borderRadius: '4px 0 0 4px',
      backgroundColor: 'rgba(76,151,255,0.07)',
      cursor: 'pointer',
      '&:hover': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
      },
      flexGrow: 2,
      textAlign:'center',
      paddingTop: 10,
      textAlign:'center',
      color: '#525966',
      fontFamily: "SF Pro",
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    periodButtonLast:{
      boxSizing: 'border-box',
      height: 40,
      width: 159,
      border: '1px solid #4C97FF',
      borderRadius: '0 4px 4px 0',
      backgroundColor: '#4C97FF',
      cursor: 'pointer',
      '&:hover': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
      },
      flexGrow: 2,
      textAlign:'center',
      paddingTop: 10,
      textAlign:'center',
      color: '#525966',
      fontFamily: "SF Pro",
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    periodButtonMiddle:{
      boxSizing: 'border-box',
      height: 40,
      width: 159,
      border: '1px solid #D0D4DB',
      // borderRadius: '0 0 0 0',
      backgroundColor: '#FFFFFF',
      cursor: 'pointer',
      '&:hover': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
      },
      flexGrow: 3,
      textAlign:'center',
      paddingTop: 10,
      textAlign:'center',
      color: '#525966',
      fontFamily: "SF Pro",
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    selectContinent: {
        fontWeight: 400,
        boxSizing: 'border-box',
        height: 48,
        width: 262,
    },
    selectCountries: {
        fontWeight: 400,
        boxSizing: 'border-box',
        height: 48,
        width: 262,
    },
    boxSelectCountries: {
        flexGrow: 1,
        paddingLeft:4,
    },
    boxSelectState: {
        flexGrow: 2,
    },
    menuItemFlag: {
        marginRight: theme.spacing(1),
        width: 24,
        minWidth: 24,
        height: 16,
        minHeight: 16,
    },
    price: {
      fontSize: 40,
      letterSpacing: 0,
      lineHeight: '48px',
      fontWeight:'bold'
    },
    buyButtonBox: {
      justifyContent: 'flex-end',
      display:'flex',
    },
    buyButtonInsideBox: {
      flexGrow: 1,
    },
    cancelButtonInsideBox: {
      flexGrow: 2,
    },
    rotateBox : {
      flexGrow: 1,
    },
    buyButton:{
      height: 48,
      width: 144,
      borderRadius: '4px',
      backgroundColor: '#4C97FF',
    },
    cancel:{
      color: '#4C97FF',
      fontFamily: "SF Pro",
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign:'center',
      paddingTop: 20,
      paddingRight:15,
    }
}))

const NewPortModal = (props) => {

  const classes = useStyles()

  const { getAccessTokenSilently } = useAuth0()

  const close = () => {
    props.dispatch(actCloseCreatePortDialog())
    resetState()
  }

  const resetState = () => {
    setPortType('limit')
    setPortContinent('*')
    setPortCountry('*')
    setPortPrice(0)
    setButtonState(false)
    sePortVolume(1)
    sePortsNumber(100)
    sePortsConnectionType('both')
    setPortsPeriod(1)
    setPortRotateOption(true)
    setChangedState(false)
    setRotationTime(5)
    setPortRegion('*')
    setPortCity(null)
    setPortRegionState(true)
    setPortCityState(true)
  }

  useEffect(() => {
          resetState()
  }, [props.activePortDialog])


  // console.log('PORT PRICE : '+props.ports.newPortPrice)

  const newPortPrice = +props.ports.newPortPrice

  const [portContinent, setPortContinent] = React.useState('*')
  const [portCountry, setPortCountry] = React.useState('*')
  const [portType, setPortType] = React.useState('limit')
  const [portPrice, setPortPrice] = React.useState(0)
  const [buttonState, setButtonState] = React.useState(true)
  const [portVolume, sePortVolume] = React.useState(1)
  const [portsNumber, sePortsNumber] = React.useState(100)
  const [portsConnectionType, sePortsConnectionType] = React.useState('both')
  const [portsPeriod, setPortsPeriod] = React.useState(1)
  const [portsRotateOption, setPortRotateOption] = React.useState(true)
  const [stateChanged, setChangedState] = React.useState(false)
  const [rotationTime, setRotationTime] = React.useState(5)
  const [portRegion, setPortRegion] = React.useState('*')
  const [portCity, setPortCity] = React.useState(null)
  const [portRegionState, setPortRegionState] = React.useState(true)
  const [portCityState, setPortCityState] = React.useState(true)

  // console.log('CHANGES: '+ stateChanged)

  const handleRotateOptionChange = (event) => {
      setPortRotateOption(event.target.checked)
  }

  const handlePortContinentChange = (event) => {
    setPortContinent(event.target.value)
    setChangedState(true)
  }

  const handleChangePortType = (type) => {
    setPortType(type)
    setChangedState(true)
  }

  const handlePortCountryChange = (event) => {
    setPortCountry(event.target.value)
    setChangedState(true)
  }

  const handlePortRegionChange = (event) => {
    setPortRegion(event.target.value)
    setChangedState(true)
  }

  const handlePortCityChange = (event) => {
    setPortCity(event.target.value)
    setChangedState(true)
  }

  const handleCreatePort = (event) => {
    if (portPrice <= 0) {
        return
    }
    const ipAutoRotation = portsRotateOption?'timer':'api'
    const portsNum = portType=='limit'?portsNumber:1

    const data = {
          user_id: props.account.userID,
          user_email: props.account.email,
          price: portPrice,
          time_period: portsPeriod,
          traffic: {
             type: portType,
             amount: portVolume
         },
         geo: {
           region: portContinent,
           country: portCountry,
           state: portRegion,
           city: portCity,
           connection_type: portsConnectionType
         },
         access: {
           type: "auth",
           whitelist_ips: [],
           port_amount: portsNum
         },
         switch: {
           type: ipAutoRotation,
           timer: rotationTime,
         }
       }
       props.dispatch(actSetOpenBuyConfirmDialog(data))
       // close()
  }

  const handlePortVolumeChange = (event) => {
    sePortVolume(event.target.value)
    setChangedState(true)
  }

  const handlePortsNumberChange = (event) => {
    sePortsNumber(event.target.value)
    // setChangedState(true)
  }

  const handleRotationTimeChange = (event) => {
    setRotationTime(event.target.value)
  }

  const handlePortsConnectionTypeChange = (type) => {
    sePortsConnectionType(type)
    // setChangedState(true)
  }

  const handlePortsPeriodChange = (period) => {
    setPortsPeriod(period)
    setChangedState(true)
  }

  useEffect(() => {
          props.dispatch(actLoadCountriesList(getAccessTokenSilently))
          // setChangedState(true)
  }, [props.activePortDialog])

  useEffect(() => {
      if (portCountry != '*' && portCountry != null && portCountry !== undefined) {
        setPortRegionState(false)
        props.dispatch(actLoadRegionList(portCountry, getAccessTokenSilently))
      }
      // setChangedState(true)
  }, [portCountry])

  useEffect(() => {
      if (portRegion != '*' && portRegion != null && portRegion !== undefined) {
        setPortCityState(false)
        props.dispatch(actLoadCitiesList(portCountry, portRegion, getAccessTokenSilently))
      }
      // setChangedState(true)
  }, [portRegion])

  useEffect(() => {
          setPortPrice(props.newPortPrice)
          if (props.newPortPrice > (props.balance/100)){
            setButtonState(true)
          }else {
            setButtonState(false)
          }
  }, [stateChanged, newPortPrice])

  useEffect(() => {
      if (stateChanged) {
        let trafficAmount = portType=='limit'?portVolume:0
        let portsNum = portType=='limit'?portsNumber:1

        let data = {
          time_period: portsPeriod,
          traffic: {
            type: portType,
            amount: portVolume
          },
          geo: {
            region: portContinent,
            country: portCountry,
            state: portRegion,
            city:portCity,
            connection_type: portsConnectionType,
          },
          access: {
            port_amount: portsNum
          }
        }

        props.dispatch(actGetPortPrice(data, getAccessTokenSilently))
        setChangedState(false)
      }
  }, [stateChanged, props.activePortDialog])

  const commonSelectProps = {
      className: classes.selectContinent,
  }

  const commonSelectCountriesProps = {
      className: classes.selectCountries,
  }

  const commonFormControlProps = {
      variant: 'outlined',
  }

  return (
    <>
        <Modal open={props.activePortDialog} onClose={close}>
          <Box className={classes.main}>
              <CloseIcon className={classes.closeIcon} onClick={close} />
              <Box className={classes.content}>
                <span className={classes.title}>New Port</span>
                <Box className={classes.typeBox}>
                  <Box className={classes.typeButtonTraffic} style={{backgroundColor: portType=='limit'?'rgba(76,151,255,0.07)':'#FFFFFF', border:portType=='limit'?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handleChangePortType('limit')}>By traffic</Box>
                  <Box className={classes.typeButtonUnlimited} style={{backgroundColor: portType=='unlimit'?'rgba(76,151,255,0.07)':'#FFFFFF', border:portType=='unlimit'?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handleChangePortType('unlimit')}>Unlimited</Box>
                </Box>
                <Box className={classes.selectBox}>
                  <FormControl {...commonFormControlProps}>
                  <span className={classes.smallTitle}>Continent</span>
                  <Select {...commonSelectProps} onChange={handlePortContinentChange} value={portContinent}>
                  <MenuItem value="*"><em>All</em></MenuItem>
                  {props.ports?.data?.continent.map((item) => (
                      <MenuItem value={item["code"]}>{continentCodeToNames[item["code"]]}</MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                  <Box className={classes.boxSelectCountries}>
                    <FormControl {...commonFormControlProps}>
                    <span className={classes.smallTitle}>Country</span>
                    <Select {...commonSelectCountriesProps} onChange={handlePortCountryChange} value={portCountry}>
                    <MenuItem value="*"><em>All</em></MenuItem>
                    {props.ports?.data?.country.map((item) => {
                      if (continentToCountries[portContinent]?.includes(item["code"]) || portContinent=='*') {
                      return (  <MenuItem value={item["code"]}>
                        <ReactCountryFlag countryCode={item["code"]} svg className={classes.menuItemFlag} />
                        &nbsp;{countryNames[item["code"]]}</MenuItem>)
                      }

                    })}
                    </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className={classes.selectBox}>
                  <FormControl {...commonFormControlProps}>
                  <span className={classes.smallTitle}>State</span>
                  <Select {...commonSelectProps} onChange={handlePortRegionChange} value={portRegion}  disabled={portRegionState}>
                  <MenuItem value="*"><em>All</em></MenuItem>
                  {props.ports?.regions?.regions?.map((item) => (
                      <MenuItem value={item["state_code"]}>{item["state_name"]}</MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                  <Box className={classes.boxSelectCountries}>
                    <FormControl {...commonFormControlProps}>
                    <span className={classes.smallTitle}>City</span>
                    <Select {...commonSelectCountriesProps} onChange={handlePortCityChange} value={portCity} disabled={portCityState}>
                    <MenuItem value="*"><em>All</em></MenuItem>
                    {props.ports?.cities?.cities?.map((item) => (
                        <MenuItem value={item["name"]}>{item["name"]}</MenuItem>
                    ))}
                    </Select>
                    </FormControl>
                  </Box>
                </Box>
                {portType == 'unlimit' ? (
                  <Box className={classes.periodBox}>
                      <span className={classes.smallTitle}>Period</span>
                      <Box className={classes.periodBox2}>
                        <Box className={classes.periodButtonFirst} style={{backgroundColor: portsPeriod==1?'rgba(76,151,255,0.07)':'#FFFFFF',border:portsPeriod==1?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handlePortsPeriodChange(1)}>1 Day</Box>
                        <Box className={classes.periodButtonMiddle} style={{backgroundColor: portsPeriod==7?'rgba(76,151,255,0.07)':'#FFFFFF', border:portsPeriod==7?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handlePortsPeriodChange(7)}>1 Week</Box>
                        <Box className={classes.periodButtonLast} style={{backgroundColor: portsPeriod==30?'rgba(76,151,255,0.07)':'#FFFFFF', border:portsPeriod==30?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handlePortsPeriodChange(30)}>1 Month</Box>
                      </Box>
                  </Box>
              ):portType == 'limit' ? (
                  <Box className={classes.selectBox}>
                    <FormControl {...commonFormControlProps}>
                    <span className={classes.smallTitle}>Volume</span>
                    <Select {...commonSelectCountriesProps} onChange={handlePortVolumeChange} value={portVolume}>
                        <MenuItem value="1">1 Gb</MenuItem>
                        <MenuItem value="2">2 Gb</MenuItem>
                        <MenuItem value="3">3 Gb</MenuItem>
                        <MenuItem value="5">5 Gb</MenuItem>
                        <MenuItem value="10">10 Gb</MenuItem>
                        <MenuItem value="15">15 Gb</MenuItem>
                        <MenuItem value="20">20 Gb</MenuItem>
                        <MenuItem value="30">30 Gb</MenuItem>
                        <MenuItem value="40">40 Gb</MenuItem>
                        <MenuItem value="50">50 Gb</MenuItem>
                        <MenuItem value="60">60 Gb</MenuItem>
                        <MenuItem value="70">70 Gb</MenuItem>
                        <MenuItem value="80">80 Gb</MenuItem>
                        <MenuItem value="90">90 Gb</MenuItem>
                        <MenuItem value="100">100 Gb</MenuItem>
                    </Select>
                    </FormControl>
                    <Box className={classes.boxSelectCountries}>
                      <FormControl {...commonFormControlProps}>
                      <span className={classes.smallTitle}>Ports Number</span>
                      <Select {...commonSelectCountriesProps} onChange={handlePortsNumberChange} value={portsNumber}>
                        <MenuItem value="100">100</MenuItem>
                        <MenuItem value="200">200</MenuItem>
                        <MenuItem value="300">300</MenuItem>
                        <MenuItem value="400">400</MenuItem>
                      </Select>
                      </FormControl>
                    </Box>
                </Box>
              ):(<></>)
              }
                <Box className={classes.periodBox}>
                  <span className={classes.smallTitle}>Type</span>
                  <Box className={classes.periodBox2}>
                    <Box className={classes.periodButtonFirst} style={{backgroundColor: portsConnectionType=='wifi'?'rgba(76,151,255,0.07)':'#FFFFFF', border:portsConnectionType=='wifi'?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handlePortsConnectionTypeChange('wifi')}>WiFi</Box>
                    <Box className={classes.periodButtonMiddle} style={{backgroundColor: portsConnectionType=='mobile'?'rgba(76,151,255,0.07)':'#FFFFFF', border:portsConnectionType=='mobile'?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handlePortsConnectionTypeChange('mobile')}>Cellular</Box>
                    <Box className={classes.periodButtonLast} style={{backgroundColor: portsConnectionType=='both'?'rgba(76,151,255,0.07)':'#FFFFFF', border:portsConnectionType=='both'?'1px solid #4C97FF':'1px solid #D0D4DB'}} onClick={()=>handlePortsConnectionTypeChange('both')}>Both</Box>
                  </Box>
                </Box>
                <Box className={classes.priceBox}>
                  <span className={classes.smallTitle}>Price</span>
                  <Box className={classes.price}>${portPrice}</Box>
                </Box>
                <Box className={classes.bottomBox}>
                    <Box className={classes.rotateBox}>
                    <FormControlLabel control={
                        <Switch checked={portsRotateOption} onChange={handleRotateOptionChange} />
                      } label={portsRotateOption?"IP Rotation by time":"IP Rotation by link enabled"}/>
                        {portsRotateOption &&
                          <FormControl sx={{ m: 1, minWidth: 30 }} size="small" variant="outlined">
                            <Select onChange={handleRotationTimeChange} value={rotationTime}>
                              <MenuItem value="5">5</MenuItem>
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="15">15</MenuItem>
                            </Select>
                          </FormControl>
                        }
                    </Box>
                    <Box className={classes.buyButtonInsideBox}>
                        <Box className={classes.buyButtonBox}>
                          <Box className={classes.cancel}><Link href="#" onClick={close}>CANCEL</Link></Box>
                            <Button
                                disabled={buttonState}
                                className={classes.buyButton}
                                variant='contained'
                                color='primary'
                                onClick={handleCreatePort}>
                                    Buy
                            </Button>
                          </Box>
                         </Box>
                    </Box>
                </Box>
                <PortBuyConfirmDialog />
          </Box>
        </Modal>

    </>
  )
}

const mapStateToProps = (state) => ({
  activePortDialog: state.ports.modelOpen,
  ports: state.ports,
  newPortPrice: state.ports.newPortPrice,
  balance: state.account.balanceCents,
  account: state.account,
})

export default connect(mapStateToProps)(NewPortModal)
