import React, { useMemo } from 'react';
import { useAuth0 } from "../redux/auth"
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import './css/FaqPage.css';
import { useEffect } from 'react';
import { getActiveItem } from '../redux/backend/activeMenuItem';




const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<AddIcon />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	}
}));




const FaqPage = (props) => {

	const mainStyles = useMemo(() => makeStyles(window.innerWidth > 480 ?
		{
		maindiv: {
			width: 704,
			position: 'relative',
			margin: 'auto',
			marginTop: 70
		},
		typographySummary: {
			fontFamily: 'SF Pro Medium',
			fontSize: 20,
			color: '#525966',
			fontSize: 20,
			letterSpacing: 0,
			fontWeight: 500
		},
		typographyDetails: {
			fontFamily: 'SF Pro Regular',
			color: '#6C7380',
			fontSize: 16,
			letterSpacing: 0
		}
	} : {
		maindiv: {
			// width: 360,
			position: 'relative',
			margin: 'auto',
			marginTop: 70
		},
		typographySummary: {
			fontFamily: 'SF Pro Medium',
			fontSize: 20,
			color: '#525966',
			fontSize: 20,
			letterSpacing: 0,
			fontWeight: 500
		},
		typographyDetails: {
			fontFamily: 'SF Pro Regular',
			color: '#6C7380',
			fontSize: 16,
			letterSpacing: 0
		}
	}), []);

	const [expanded, setExpanded] = React.useState('panel1');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const classes = mainStyles();

	const { getAccessTokenSilently } = useAuth0();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getActiveItem('FAQ'));
	}, [dispatch]);


	return (
		<div className={classes.maindiv}>
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel1d-content" id="panel1d-header" expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>What is mobile proxies?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						Mobile proxies are proxies that are running on different mobile phones.
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>Which protocol are used for connection?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						For current moment only SOCKS5 protocol are available for connection to proxy.
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel3d-content" id="panel3d-header" expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How to configure BigMamа proxies?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails} display="block">
						<li>Our proxies could be used with various software:</li>
						<li>Chrome/macOS/Windows - you need create PORT NOAUTH for bought proxies and after use any addon or software you need.
							As example you can use proxyfier.</li>
						<li>Firefox - by default is not working too, to use it with Firefox you can use <a href="https://addons.mozilla.org/en-US/firefox/addon/bigmama-proxy-connect-addon/" target="_blank">BigMama Proxy Connect</a> extension
							or any other</li>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel35'} onChange={handleChange('panel35')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel3d-content" id="panel3d-header" expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>What is a PORT NOAUTH?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails} display="block">
						PORT NOAUTH - allow user create unique port for connection to proxy without username and password.
						You can configure PORT NOAUT for each proxy listed in "My Proxies" tab. While configuration you should specify ip address
						from which you will perform connection to proxy.
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel4d-content" id="panel4d-header" expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>Why proxy change the ip adress?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						As proxies is running on users mobile phones, that means If owner of the phone change location or switch from wifi to cell
						or vice versa or switch on VPN and in this cases the ip will changed.
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel5d-content" id="panel5d-header" expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>What means activity, uptime, new, clean params?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						<p>Activity - shows how often proxy is online in 24h.
							Example : activity 80% means that proxy will be online near 19h.
							But this not mean that online calculated from moment you bought proxy, proxy can be offline from 10 mins to few hours and then back online.
							Activity 50% means proxy will be online 12h per day and it stability will be the same as it activity.
						</p>
						<p>Uptime - how long ago proxy was seen first time in our network.</p>
						<p>New - was not sold to anyone in past 30 days.</p>

						<p>Clean - depends on quantity of blacklists in which proxy are present.</p>

					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel6d-content" id="panel6d-header" expandIcon={expanded === 'panel6' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How work refund process?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>

						In case of proxy stop responding in a first 4 hours, it could be refunded, in "My Proxies" list, refund button will be shown, when button pressed,
						system doublecheck if socks is truly not responsive and if so refund is issued to your account.
						When you click refund button system will check proxy traffic if its less then 100Mb and speed less then 500kbps, proxy will be refunded.
						Speed check works only if proxy shared in two or one user. Refund button will be shown for first 4h.
						If you cannot refund proxy by some reason, please contact support via  <a href="https://t.me/bigma_network">telegram</a>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel7d-content" id="panel7d-header" expandIcon={expanded === 'panel7' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>What differents between shared and private?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>

						Shared means that same proxy could be rented by other people, up to 4 times. Private means that as soon as you bought it it will be removed from marked and will be used only by you.

					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel8d-content" id="panel8d-header" expandIcon={expanded === 'panel8' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>What about geo for mobile proxies?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						Even the mobile phones have no fixed location, IP addresses they used to access internet still registered to some locations, quite often that is cell operator head office address,
						or other location. Also, different lookup databases could show diffferent location, IP could change and appear in a different state, or even country.

						When user is connected to WiFi it is easier, then it is acting as a resedential proxy with address etc.
						You can read  additional info about mobile geo, you can read it in the <a href="https://www.if-so.com/geo-targeting/#:~:text=IP%20based%20geolocation%20services%20provide,the%20location%20of%20the%20device" target="_blank">article</a>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel9d-content" id="panel9d-header" expandIcon={expanded === 'panel9' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>If I need proxy more then for one day?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						There is auto-renew option which could be enabled in "My Proxies" tab, then each day it would be renewed,
						and your balance charged.
						<img src="https://i.imgur.com/j5xglyn.png" style={{ width: '500px' }} className='faq-img'/>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel10d-content" id="panel10d-header" expandIcon={expanded === 'panel10' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How clean param are calculated?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>

						<p>сlean = 5 , 0 blacklist exist</p>
						<p>сlean = 4 , {'<'} 3 blacklist exist</p>
						<p>сlean = 3, {'<'} 7 blacklist exist</p>
						<p>сlean = 2, {'<'} 10 blacklist exist</p>
						<p>сlean = 1, {'>'} 10 blacklist exist</p>
						Checking is done by the following blacklists:
						<p>
							dul.pacifier.net,
							cbl.abuseat.org,
							sbl.spamhaus.org,
							xbl.spamhaus.org,
							bl.spamcop.net,
							psbl.surriel.com,
							b.barracudacentral.org,
							ubl.lashback.com,
							spam.spamrats.com,
							virus.rbl.msrbl.net,
							virus.rbl.jp,
							bl.emailbasure.org,
							bl.spameatingmonkey.net,
							combined.abuse.ch,
							bogons.cymru.com,
							db.wpbl.info,
							dnsbl.inps.de,
							dnsbl.sorbs.net,
							dyna.spamrats.com,
							noptr.spamrats.com,
							osps.dnsbl.net.au,
							omrs.dnsbl.net.au,
							ix.dnsbl.manitu.net,
							owps.dnsbl.net.au,
							proxy.block.transip.nl,
							proxy.bl.gweep.ca,
							ralays.bl.kundenserver.de,
							spam.rbl.msrbl.net,
						</p>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel11d-content" id="panel11d-header" expandIcon={expanded === 'panel11' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How offten proxies rotate on port?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						<p>- Automatic IP rotation - changes proxies on each port every 5 minutes.</p>
						<p>- Link IP rotation - user can change proxy by request but not often then 5 minutes.</p>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel12d-content" id="panel12d-header" expandIcon={expanded === 'panel12' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How many ports(threads) I will get per package?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						<p>- By traffic - up to 400 ports(threads).</p>
						<p>- Unlimited - 1 port</p>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel12d-content" id="panel12d-header" expandIcon={expanded === 'panel12' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How many whitelist ips I can add per package?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						<p>- By traffic - up 20.</p>
						<p>- Unlimited - 1 IP for whitelist</p>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
				<AccordionSummary className="classes.panelSummary" aria-controls="panel13d-content" id="panel13d-header" expandIcon={expanded === 'panel13' ? <RemoveIcon /> : <AddIcon />}>
					<Typography className={classes.typographySummary}>How can I get refund for port?</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className={classes.typographyDetails}>
						<p>- By traffic - port with traffic limit 1 Gb: if total traffic on port less or equal 10 Mb. Port with traffic more 1 Gb: each new Gb plus more then 10 Mb will be rounded to hightest number and with per 1Gb price rate.
							Example: 1.011 Gb will calculated as 2 Gb </p>
						<p>- Unlimited - only if total traffic on port less or equal 10 Mb</p>
					</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

const mapStateToProps = (state) => ({
	...state.faq,
});

export default connect(mapStateToProps)(FaqPage);
