import React, { useState, useEffect } from 'react'
import { useAuth0 } from "../../redux/auth"
import { connect, useSelector } from 'react-redux'
import { makeStyles, Box, Tabs, Tab, Link, Snackbar, Button} from '@material-ui/core'
import NoResults from '../NoResults'
import { headerStyle } from '../Shop'
import { pageTitle, leftBorder, topBorder } from '../../styles/common'
import { useLocation } from 'react-router-dom'
import PortsTable from './PortsTable'
import PortsSidebar from './PortsSidebar'
import { actOpenCreatePortDialog, actResetUpdatePortWhitelistResult,
  actResetReActivateResult, actResetRefundResult } from '../../redux/backend/ports'
import { Alert } from '@material-ui/lab'



const useStyles = makeStyles((theme) => ({
    tab: {
        height: '100vh',
        width: '100%',

        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr auto',
    },
    header: {
        ...headerStyle(theme),
        paddingBottom: theme.spacing(1.5),
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 2,
        marginTop: '60px',
    },
    content: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 2,
        gridRowEnd: 3,

        overflowY: 'scroll',
    },
    sidebar: {
        ...leftBorder(theme),
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 3,
    },
    footer: {
        padding: 0,
        paddingLeft: theme.spacing(1),
        ...topBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 3,
        gridRowEnd: 4,
    },
    title: {
        ...pageTitle,
    },
    buyButton:{
      height: 30,
      width: 120,
      borderRadius: '4px',
      backgroundColor: '#4C97FF',
    }
}))

const Ports = (props) => {
  const classes = useStyles()

  const location = useLocation()

  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const portPriceState = useSelector((state) => state.ports.newPortPriceError)
  const portCreateState = useSelector((state) => state.ports.createError)
  const updateWhitelistErrorState = useSelector((state) => state.ports.updatePortWhiteListError)
  const reactivatePortError = useSelector((state) => state.ports.reactivatePortError)
  const refundPortError = useSelector((state) => state.ports.refundPortError)
  const regionsLoadError = useSelector((state) => state.ports.regionsLoadError)
  const citiesLoadError = useSelector((state) => state.ports.citiesLoadError)

  const actResetGetPricePortResult = () => ({
	    type: 'RESET_GET_PRICE_RESULT',
	    reduce: (state) => ({
	        ...state,
	        ports: {
            ...state.ports,
            newPortPriceError: null,
          }
	    })
	})

  const actResetRegionsLoadError = () => ({
	    type: 'RESET_REGION_LOAD_RESULT',
	    reduce: (state) => ({
	        ...state,
	        ports: {
            ...state.ports,
            regionsLoadError: null,
          }
	    })
	})

  const actResetCitiesLoadError = () => ({
	    type: 'RESET_CITIES_LOAD_RESULT',
	    reduce: (state) => ({
	        ...state,
	        ports: {
            ...state.ports,
            citiesLoadError: null,
          }
	    })
	})

  const actResetCreatePortResult = () => ({
	    type: 'RESET_CREATE_PORT_RESULT',
	    reduce: (state) => ({
	        ...state,
	        ports: {
            ...state.ports,
            createError: null,
          }
	    })
	})

  const handleCloseSnackbar = (event, reason) => {
			props.dispatch(actResetGetPricePortResult())
	}

  const handleCloseCreatePortSnackbar = (event, reason) => {
			props.dispatch(actResetCreatePortResult())
	}

  const handleOpenNewPortDialog = () => {
      props.dispatch(actOpenCreatePortDialog())
  }

  const handleCloseSnackbarWhitelistUpdate = (event, reason) => {
      props.dispatch(actResetUpdatePortWhitelistResult())
  }

  const handleCloseSnackbarReActivateError = (event, reason) => {
      props.dispatch(actResetReActivateResult())
  }

  const handleCloseSnackbarRefundError = (event, reason) => {
      props.dispatch(actResetRefundResult())
  }

  const handleCloseSnackbarRegionsLoadError = (event, reason) => {
      props.dispatch(actResetRegionsLoadError())
  }

  const handleCloseSnackbarCitiesLoadError = (event, reason) => {
      props.dispatch(actResetCitiesLoadError())
  }

  return (
      <Box className={classes.tab}>
          <Box className={classes.header}>
            <span className={classes.title}>Ports&nbsp;</span>
            <Button
                className={classes.buyButton}
                variant='contained'
                color='primary'
                onClick={handleOpenNewPortDialog}>
                    New Port
            </Button>
          </Box>
          <Box className={classes.content}>
            <PortsTable />
          </Box>
          <Box className={classes.sidebar}><PortsSidebar /></Box>
          <Snackbar
  		        	open={portPriceState}
  					onClose={handleCloseSnackbar}
  		        	autoHideDuration={3000}>
  				<Alert severity="error">Error getting port price</Alert>
  				</Snackbar>
          <Snackbar
  		        	open={portCreateState}
  					onClose={handleCloseCreatePortSnackbar}
  		        	autoHideDuration={3000}>
  				<Alert severity="error">Error creating port</Alert>
  				</Snackbar>
          <Snackbar
               open={updateWhitelistErrorState}
           onClose={handleCloseSnackbarWhitelistUpdate}
               autoHideDuration={3000}>
          <Alert severity="error">Error updating whitelist</Alert>
         </Snackbar>
         <Snackbar
              open={reactivatePortError}
          onClose={handleCloseSnackbarReActivateError}
              autoHideDuration={3000}>
         <Alert severity="error">Error while reactivating port</Alert>
        </Snackbar>
        <Snackbar
             open={refundPortError}
         onClose={handleCloseSnackbarRefundError}
             autoHideDuration={3000}>
        <Alert severity="error">Error while refunding port</Alert>
       </Snackbar>
       <Snackbar
            open={regionsLoadError}
        onClose={handleCloseSnackbarRegionsLoadError}
            autoHideDuration={3000}>
       <Alert severity="error">Error while getting regions list</Alert>
      </Snackbar>
      <Snackbar
           open={citiesLoadError}
       onClose={handleCloseSnackbarCitiesLoadError}
           autoHideDuration={3000}>
      <Alert severity="error">Error while getting cities list</Alert>
     </Snackbar>
      </Box>
  )
}

const mapStateToProps = (state) => ({
  ...state,
})

export default connect(mapStateToProps)(Ports)
