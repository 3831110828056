/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ClipLoader } from 'react-spinners';
import { Switch, TextField } from '@material-ui/core';
import { actLoadActiveLeases, actSetLeaseNote, actSwitchAutoRenew } from '../../redux/backend/active-leases';
import { Link, NavLink } from 'react-router-dom';
import { CopySnackbar } from '../my-proxies/CopyButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useAuth0 } from "../../redux/auth"
import { actConfirmRenew } from '../../redux/backend/renew';
import { humanizedActivity, humanizedAgeSinceFirstSeen } from '../../redux/backend/proxies';
import humanFormat from 'human-format';
import copyConnectionString from '../../components/admin/LeaseInfo';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { actStartLease } from '../../redux/backend/lease';
import './style.css';
import { actUpdatePortWhitelist } from '../../redux/backend/ports';
import { actRequestAuthPort } from '../../redux/backend/authport';
import { countryNames } from '../../data/countryNames';
import IPScoreSection from '../IPScoreSection';
import SpeedCheckSection from '../SpeedCheckSection';
import { actLoadLeaseHistory } from '../../redux/backend/lease-history';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const CopyConnectionStringLink = (props) => {
//   const socks = props.lease.socks;
//   const connectionString = 'socks5h://' + socks.user + ':' + socks.pwd + '@' + socks.ip + ':' + socks.port;

//   const [snackbarOpen, setSnackbarOpen] = useState(false);

//   const copyConnectionString = () => {
//     navigator.clipboard.writeText(connectionString);
//     setSnackbarOpen(true);
//   };

//   return (
//     <>
//       <Link href='#' onClick={copyConnectionString}>Copy connection string</Link>
//       <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
//     </>
//   );
// };

const MyProxieDetails = (props) => {

  const { dispatch, neverLoaded } = props;
  const { getAccessTokenSilently } = useAuth0();
  const lease = props.lease;
  const [showMore, setShowMore] = useState(false);
  const [prevEditNodeLeaseId, setPrevEditNodeLeaseId] = useState(null);
  const [tempNote, setTempNote] = useState("");
  // const [addingNote, setAddingNote] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  // const [showExtraInfo, setShowExtraInfo] = useState(false);
  // const [selectedTab, setSelectedTab] = useState("0");
  const [buttonStatus, setButtonDisbled] = useState(true);
  const [errorWhiteList, setWhiteListError] = useState(false);
  const [whitelist, setWhitelist] = useState("");
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (neverLoaded) {
      dispatch(actLoadActiveLeases(getAccessTokenSilently));
    }
  }, [neverLoaded, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    dispatch(getActiveItem('Proxy Details'));
  }, [dispatch]);

  useEffect(() => {
    if (lease?.id !== prevEditNodeLeaseId) {
      setTempNote(lease?.note);
    }
    // setAddingNote(false);
    setPrevEditNodeLeaseId(lease?.id);
  }, [lease, setTempNote, prevEditNodeLeaseId, setPrevEditNodeLeaseId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!lease) {
    return <div />;
  }

  const hideShowPasswordIcon = passwordHidden ?
    <VisibilityIcon onClick={() => setPasswordHidden(false)} /> :
    <VisibilityOffIcon onClick={() => setPasswordHidden(true)} />;

  // const handleRenew = () => {
  //   props.dispatch(actConfirmRenew(lease));
  // };

  // const onAddNoteClick = () => {
  //   setAddingNote(true);
  // };
  const onEditNoteBlur = () => {
    // setAddingNote(false);
    setTempNote(tempNote?.trim());
    props.dispatch(actSetLeaseNote(getAccessTokenSilently, lease, tempNote));
  };

  const onNoteChange = (event) => {
    const val = event.target.value;
    setTempNote(val);
    if (!val) {
      props.dispatch(actSetLeaseNote(getAccessTokenSilently, lease, val));
    }
  };



  let leaseTypeStatus = "Private";

  if (props.stars <= 5) {
    if (props.leases != null && props.leases.count > 0) {
      leaseTypeStatus = props.leases.count + " of 4"; // TODO config from backend
    }
  } else {
    if (props.leases != null && props.leases.count > 0) {
      leaseTypeStatus = props.leases.count + " of 3"; // TODO config from backend
    }
  }

  let stats = props?.lease?.stats;
  if (!stats) {
    stats = {
      reqs: 0,
      failPct: 0,
      inBytes: 0,
      outBytes: 0,
    };
  }

  const handleWhiteListChange = (e) => {
    const regexExpIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

    if (e.target.value == "") {
      setButtonDisbled(true);
      setWhiteListError(false);
    }

    const ipsArr = e.target.value.split(',');
    let checkedCount = 0;

    ipsArr.forEach(element => {
      if (!element.match(regexExpIP)) {
        setButtonDisbled(true);
        setWhiteListError(true);
      } else {
        checkedCount++;
      }
    });

    if (checkedCount == ipsArr.length) {
      setWhiteListError(false);
      setButtonDisbled(false);
    }
    setWhitelist(e.target.value);
  };

  const socksWhiteList = whitelist !== "" ? whitelist : props?.lease?.authPort?.whitelist;

  const portData = props?.ports?.selectedPort;

  const switchAutoRenew = (lease) => {
    dispatch(actSwitchAutoRenew(getAccessTokenSilently, lease));
  };

  const handleBuyClick = () => {
    if (props.onBuy) {
      props.onBuy();
    }
    props.dispatch(actStartLease(props.selected));
  };

  const CopyConnectionStringLink = (props) => {
    const socks = props.lease.socks;
    const connectionString = 'socks5h://' + socks.user + ':' + socks.pwd + '@' + socks.ip + ':' + socks.port;

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const copyConnectionString = () => {
      navigator.clipboard.writeText(connectionString);
      setSnackbarOpen(true);
    };

    return (
      <>
        <div onClick={copyConnectionString}>Copy connection string</div>
        <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
      </>
    );
  };

  // const saveWhitelist = () => {
  //   const ipsArr = whitelist?.split(',');
  //   const body = {
  //     package_id: portData?.id,
  //     whitelist_ips: ipsArr
  //   };
  //   props.dispatch(actUpdatePortWhitelist(body, getAccessTokenSilently));
  //   setWhitelist("");
  // };

  const requestPort = () => {
    props.dispatch(actRequestAuthPort(getAccessTokenSilently, lease.id, JSON.stringify({ whitelist: whitelist })));
    setWhitelist("");
  };

  const socksIp = (
    lease.authPort !== undefined &&
    (lease.authPort.node !== undefined && lease.authPort.node !== "" && lease.authPort.node !== null)) ?
    lease.authPort.node : '';

  const socksPort = (
    lease.authPort !== undefined &&
    (lease.authPort.port !== undefined && lease.authPort.port !== 0 && lease.authPort.port != null)) ?
    lease.authPort.port : '';

  return (
    <div className='my-proxies-details-page'>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Info" {...a11yProps(0)} />
            <Tab label="Socks Auth" {...a11yProps(1)} />
            <Tab label="Port NoAuth" {...a11yProps(2)} />
            <Tab label="Update Score" {...a11yProps(3)} />
            <Tab label="Test Speed" {...a11yProps(4)} />
            <Tab label="Blacklists" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {
            props?.lease ?
              <div>
                <div className='add-note-input-container'>
                  <TextField
                    variant="outlined"
                    multiline={true}
                    // rows={3}
                    rowsMax={3}
                    inputProps={{ maxLength: 200 }}
                    onBlur={onEditNoteBlur}
                    label="Add note"
                    value={tempNote}
                    onChange={onNoteChange}
                    className='add-note-input'
                  />
                </div>
                <div>
                  <div className='info-container'>
                    <div className='info-item'>
                      <div className='info-item-label'>Lease ID</div>
                      <div className='info-item-value active-link-color'>{props?.lease?.id}</div>
                    </div>
                    <div className='info-item'>
                      <div className='info-item-label'>IP Address</div>
                      <div className='info-item-value'>{props?.lease?.info?.ip}</div>
                    </div>
                    <div className={showMore ? 'info-item' : 'info-item  last-item'}>
                      <div className='info-item-label'>Country</div>
                      <div className='info-item-value'>{countryNames[props?.lease?.currentInfo?.loc?.cc]}</div>
                    </div>
                    {
                      showMore && <div className='show-more-container'>
                        <div className='info-item remove-border-radius'>
                          <div className='info-item-label '>Hostname</div>
                          <div className='info-item-value'>{ }</div>
                        </div>
                        <div className='info-item'>
                          <div className='info-item-label'>City</div>
                          <div className='info-item-value'>{props?.lease?.currentInfo?.loc?.city}</div>
                        </div>
                        <div className='info-item'>
                          <div className='info-item-label'>State</div>
                          <div className='info-item-value'>{props?.lease?.currentInfo?.loc?.reg}</div>
                        </div>
                        <div className='info-item'>
                          <div className='info-item-label'>Zip</div>
                          <div className='info-item-value'>{props?.lease?.currentInfo?.loc?.zip}</div>
                        </div>
                        <div className='info-item'>
                          <div className='info-item-label'>Isp</div>
                          <div className='info-item-value'>{props?.lease?.info?.loc?.isp}</div>
                        </div>
                        <div className='info-item'>
                          <div className='info-item-label'>Shared</div>
                          <div className='info-item-value'>
                            {props?.lease?.exc ? "Private" : `${props?.lease?.currentInfo?.leases?.count} of 4`}
                          </div>
                        </div>
                        <div className='info-item'>
                          <div className='info-item-label'>Connection</div>
                          <div className='info-item-value'>{props?.lease?.info?.conn}</div>
                        </div>
                      </div>
                    }
                    {
                      showMore ?
                        <div onClick={() => setShowMore(false)} className='show-more-btn'>Show less</div> :
                        <div onClick={() => setShowMore(true)} className='show-less-btn'>Show more</div>
                    }
                  </div>
                </div>
                <div className='info-container'>
                  <div className='info-item'>
                    <div className='info-item-label'>Uptime</div>
                    <div className='info-item-value'>{humanizedAgeSinceFirstSeen(props?.lease?.currentInfo?.firstSeen)}</div>
                  </div>
                  <div className='info-item'>
                    <div className='info-item-label'>Activity</div>
                    <div className='info-item-value'>{humanizedActivity(props?.lease?.currentInfo?.actv, props?.lease?.currentInfo?.probeActv)}</div>
                  </div>
                </div>
                <div className='total-amounts-container'>
                  <div className='total-amounts-item'>
                    <div className='total-amounts-item-label'>Total Requests</div>
                    <div className='total-amounts-item-value'>{humanFormat(stats.reqs, { decimals: 0 })}</div>
                  </div>
                  <div className='total-amounts-item'>
                    <div className='total-amounts-item-label'>Failed</div>
                    <div className='total-amounts-item-value'>{Math.floor(stats.failPct) + "%"}</div>
                  </div>
                  <div className='total-amounts-item'>
                    <div className='total-amounts-item-label'>You to Proxy</div>
                    <div className='total-amounts-item-value'>{humanFormat.bytes(stats.outBytes, { decimals: 0 })}</div>
                  </div>
                  <div className='total-amounts-item'>
                    <div className='total-amounts-item-label'>Proxy to You</div>
                    <div className='total-amounts-item-value'>{humanFormat.bytes(stats.inBytes, { decimals: 0 })}</div>
                  </div>
                </div>
                <div className='switch-container'>
                  <span>Automatically renew</span>
                  <Switch
                    size="small"
                    checked={props.selected.autoRenew}
                    onChange={() => {
                      switchAutoRenew(props?.selected);
                    }}
                  />
                </div>
              </div>
              :
              <ClipLoader
                color='#1589EE'
                size={30}
              />
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          {
            props?.lease ?
              <div style={{ marginTop: '16px' }}>
                <div className='info-container'>
                  <div className='info-item'>
                    <div className='info-item-label'>Username</div>
                    <div className='info-item-value'>{props?.lease?.socks?.user}</div>
                  </div>
                  <div className='info-item'>
                    <div className='info-item-label'>Password</div>
                    <div className='info-item-value'>{passwordHidden ? '•'.repeat(10) : lease.socks.pwd} {hideShowPasswordIcon}</div>
                  </div>
                  <div className='info-item'>
                    <div className='info-item-label'>SOCKS IP</div>
                    <div className='info-item-value'>{props?.lease?.socks?.ip}</div>
                  </div>
                  <div className='info-item'>
                    <div className='info-item-label'>SOCKS Port</div>
                    <div className='info-item-value'>{props?.lease?.socks?.port}</div>
                  </div>
                </div>
                <div className='copy-connection-string'>
                  <CopyConnectionStringLink lease={lease} />
                </div>

              </div> :
              <ClipLoader
                color='#1589EE'
                size={30}
              />
          }
        </TabPanel>
        <TabPanel value={value} index={2}>
          {
            props?.lease ?
              <div style={{ marginTop: '16px' }}>
                <div className='info-container'>
                  <div className='info-item'>
                    <div className='info-item-label'>SOCKS IP:</div>
                    <div className='info-item-value'>{socksIp}</div>
                  </div>
                  <div className='info-item'>
                    <div className='info-item-label'>SOCKS Port:</div>
                    <div className='info-item-value'>{socksPort}</div>
                  </div>
                </div>
                <div className='white-list'>
                  <TextField
                    required
                    id="whitelist"
                    label="white list ip(s), format: IP,IP,...IP"
                    variant="outlined"
                    onChange={handleWhiteListChange}
                    error={errorWhiteList}
                    value={socksWhiteList}
                  />
                </div>
                <button
                  className={!buttonStatus ? 'no-auth-save-btn' : 'no-auth-save-btn disabled-btn'}
                  // disabled={buttonStatus}
                  onClick={requestPort}
                >Save</button>
              </div> :
              <ClipLoader
                color='#1589EE'
                size={30}
              />
          }
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className='ip-score-container'>
            <IPScoreSection scoreCheck={props?.lease?.currentInfo?.scoreCheck} proxyId={props?.lease?.agentId} />
            {/* <IPScoreSection scoreCheck={props?.selected?.scoreCheck} proxyId={props?.selected?.id} /> */}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className='speed-container'>
            <SpeedCheckSection proxyId={props?.lease?.agentId} speedCheck={props?.lease?.currentInfo?.speedCheck} />
            {/* <SpeedCheckSection proxyId={props?.selected?.id} speedCheck={props?.selected?.speedCheck} /> */}
          </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
              <div className='blacklist-container'>
                {
                  props?.lease?.info?.blacklists?.listIDs?.map((item, index) => {
                    return <div className='blacklist-item' key={index}>{item}</div>;
                  })
                }
              </div>
        </TabPanel>
      </Box>
      <div className='connect-btn-container'>
        {/* <button
          id='lease_button'
          className='connect-btn'
          variant='contained'
          disableElevation
          color='primary'
          onClick={handleBuyClick}
        >
          Connect
        </button> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lease: state.activeLeases.selected,
  activeLeases: state.activeLeases,
  selected: state.proxies.selected,
  ...state.activeLeases,
});

export default connect(mapStateToProps)(MyProxieDetails);