import React from 'react'
import { useAuth0 } from "../redux/auth"
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import androidDownload from '../images/download-for-android.png'
import gpDownload from '../images/play-store.png'
import ffAddon from '../images/firefox-addon.png'
import chromeAddon from '../images/chrome-addon.png'

const mainStyles = makeStyles({
	  maindiv: {
		  width: 704,
		  position: 'relative',
		  margin: 'auto',
		  marginTop: 70,
			display: 'block',
			justifyContent: 'center',
			alignItems: 'center'
	  },
		textDiv: {
			justifyContent: 'center',
			alignItems: 'center'
		},
	  h1text: {
		  height: 120,
		  width: 496,
		  color: '#525966',
		  fontFamily: "SF Pro Regular",
		  fontSize: '14',
		  letterSpacing: 0,
		  lineHeight: '24px',
		  textAlign: 'center'
		},
		imgDiv: {
		  paddingTop: 56,
			justifyContent: 'center',
			alignItems: 'center'
		},
		imgAndroidDiv: {
			  paddingLeft: '20%'
		},
	 	imgFfAddon : {
		 paddingLeft: 50
	 	},
	 	imgChromeAddon : {
		 paddingLeft: 50
	 	}
})

const DownloadPage = (props) => {

	const classes = mainStyles()

	const { getAccessTokenSilently } = useAuth0()

	return (
		<div className={classes.maindiv}>
			<div>
				<h1 align="center">Downloads</h1>
			</div>
			<div className={classes.textDiv}>
					<p className="classes.h1text">
					BigMama Network Proxies application allows registered users to connect to proxies purchased in the market in one click.
					Only purchased proxies are displayed in the application. The application is in beta version and if there are any errors,
					write to us in Support, we will be happy to fix them. We are working to make the use of our proxies more convenie
					</p>
			</div>
			<div className={classes.imgAndroidDiv}>
				<a href='https://play.google.com/store/apps/details?id=network.bigmama.market.android' target="_blank"><img width="180" src={gpDownload}/></a>
				<a href="https://addons.mozilla.org/en-US/firefox/addon/bigmama-proxy-connect-addon/" target="_blank" className={classes.imgChromeAddon}><img src={ffAddon} width="180"/></a>
			</div>
			<div className={classes.imgAndroidDiv}>
				<a href="https://market.bigmama.network/app/app-release.apk" target="_blank"><img src={androidDownload} width="180"/></a>
				<a href="https://chrome.google.com/webstore/detail/bigmama-proxy-connect/jpalllpodmbfjooholeclopemadbfocp" target="_blank" className={classes.imgFfAddon}><img src={chromeAddon} width="180"/></a>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
    ...state.download,
})

export default connect(mapStateToProps)(DownloadPage)
