import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Link, Button, TextField, Tab, Tabs } from '@material-ui/core'
import { sidebarContentStyle } from '../ProxySidebar'
import { topBorder, inlineIcon } from '../../styles/common'
import LeaseStats from './LeaseStats'
import CopyButton, { CopySnackbar } from './CopyButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RefundButton from './RefundButton'
import { humanizedActivity, humanizedAgeSinceFirstSeen } from '../../redux/backend/proxies'
import { changedFields, infoChanged } from './util'
import InfoIcon from '@material-ui/icons/Info'
import EditIcon from '@material-ui/icons/Edit'
import { actConfirmRenew } from '../../redux/backend/renew'
import ButtonWithTooltip from '../ButtonWithTooltip'
import { useAuth0 } from "../../redux/auth"
import { actSetLeaseNote } from '../../redux/backend/active-leases'
import ProxyDetails from '../ProxyDetails'
import { TabContext, TabPanel } from '@material-ui/lab'
import { Divider } from '@material-ui/core'
import PortNoAuth from './PortNoAuth'

const sectionBase = (theme) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
})

const useStyles = makeStyles((theme) => ({
    content: {
        ...sidebarContentStyle(theme),
    },
    info: {
        flexGrow: 1,
        overflowY: 'scroll',
        gridRow: '1 / 4',
    },
    actions: {
        padding: theme.spacing(3),
        textAlign: 'center',
        ...topBorder(theme),
        gridRow: '4 / 5',
    },
    actionButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    section: {
        ...sectionBase(theme),
        borderWidth: 0,
//        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
        borderStyle: 'solid',
    },
    sectionSocks: {
        borderWidth: 0,
//        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
        borderStyle: 'solid',
    },
    sectionBase: {
        ...sectionBase(theme),
    },
    field: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        maxWidth: '100%',
        overflowWrap: 'break-word',
    },
    header: {
        fontWeight: 600,
    },
    spacer: {
        paddingTop: theme.spacing(1),
    },
    label: {
        marginRight: theme.spacing(1),
    },
    value: {
        fontWeight: 600,
    },
    link: {
        fontSize: 13,
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
    },
    inlineIcon: {
        ...inlineIcon(theme),
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
    },
    infoChangeIcon: {
        height: 18,
        position: 'relative',
        top: 4,
        fill: theme.palette.grey[500],
    },
    editNoteIcon: {
        height: 18,
        opacity: 0.8,
        cursor: 'pointer',
    },
    noteTextField: {
        width: '100%',
    },
    sectionSocksSettingText: {
      borderWidth: 0,
      borderBottomColor: theme.palette.divider,
      borderStyle: 'solid',
      marginLeft: '12px',
      paddingLeft: '12px',
      fontFamily:'SF Pro Regular',
		  color: '#6C7380',
	  	fontSize: 12,
	  	letterSpacing: 0,
      flexDirection: 'column',
    }
}))

const useTabStyles
 = makeStyles((theme) => ({
    tabContainer: {
        width: '100%',
    },
    tabPanel: {

    },
}))


export const tabStyles = makeStyles((theme) => ({
    root: {
        minWidth: 20,
        width: 20,
        textTransform: 'none',
        fontSize: 12,
        color: theme.palette.text.secondary,
        '&$selected': {
            '& *': {
              color: theme.palette.secondary.main,
            },
        },
        '&:hover': {
            '& $wrapper': {
                color: theme.palette.secondary.main,
            },
        },
    },
    selected: {},
    textColorInherit: {
        opacity: 1,
    },
    wrapper: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 500,
    },
}))


const CopyConnectionStringLink = (props) => {
    const socks = props.lease.socks
    const connectionString = 'socks5h://' + socks.user + ':' + socks.pwd + '@' + socks.ip + ':' + socks.port

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const copyConnectionString = () => {
        navigator.clipboard.writeText(connectionString)
        setSnackbarOpen(true)
    }

    return (
        <>
            <Link href='#' onClick={copyConnectionString}>Copy connection string</Link>
            <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
        </>
    )
}

const MyProxySidebar = (props) => {

    const classes = useStyles()
    const tabClasses = tabStyles()

    const lease = props.lease

    const [passwordHidden, setPasswordHidden] = useState(true)
    const [showExtraInfo, setShowExtraInfo] = useState(false)
    const [addingNote, setAddingNote] = useState(false)
    const [prevEditNodeLeaseId, setPrevEditNodeLeaseId] = useState(null)
    const [tempNote, setTempNote] = useState("")
    const [selectedTab, setSelectedTab] = useState("0")

    const leaseUsedTime = (Date.now() - lease?.start)/1000
    let staticRefund = true

    if (leaseUsedTime >= 3600) {
        staticRefund = false
    }

    useEffect(() => {
        if (lease?.id !== prevEditNodeLeaseId) {
            setTempNote(lease?.note)
        }
        setAddingNote(false)
        setPrevEditNodeLeaseId(lease?.id)
    }, [lease, setTempNote, prevEditNodeLeaseId, setPrevEditNodeLeaseId])

    const { getAccessTokenSilently } = useAuth0()

    if (!lease) {
        return <div className={classes.content} />
    }

    //const ellipsizedHost = lease.socks.host.length > 20 ? lease.socks.host.substr(0, 19) + '…' : lease.socks.host

    const hideShowPasswordIcon = passwordHidden ?
        <VisibilityIcon className={classes.inlineIcon} onClick={() => setPasswordHidden(false)} /> :
        <VisibilityOffIcon className={classes.inlineIcon} onClick={() => setPasswordHidden(true)} />

    const handleRenew = () => {
        props.dispatch(actConfirmRenew(lease))
    }

    const onAddNoteClick = () => {
        setAddingNote(true)
    }
    const onEditNoteBlur = () => {
        setAddingNote(false)
        setTempNote(tempNote?.trim())
        props.dispatch(actSetLeaseNote(getAccessTokenSilently, lease, tempNote))
    }
    const onNoteChange = (event) => {
        const val = event.target.value
        setTempNote(val)
        if (!val) {
            props.dispatch(actSetLeaseNote(getAccessTokenSilently, lease, val))
        }
    }

    // console.log(props.lease, 'props.lease')

    return (
        <div className={classes.content}>
            <div className={classes.info}>
                <div className={classes.section}>
                    <CopyButton value={lease.id} />
                    <span className={classes.label}>Lease ID:</span>
                    <span className={classes.value}>{lease.id}</span>
                </div>
                <Divider />
                <div className={classes.section}>
                    {(tempNote || addingNote) ?
                        <TextField
                            className={classes.noteTextField}
                            variant="outlined"
                            multiline={true}
                            rows={3}
                            rowsMax={3}
                            inputProps={{ maxLength: 200 }}
                            onBlur={onEditNoteBlur}
                            label="Note (max 200 chars)"
                            value={tempNote}
                            onChange={onNoteChange} />
                        : <Button onClick={onAddNoteClick}><EditIcon className={classes.editNoteIcon} />Add note</Button>}
                </div>
                <Divider />
                <div>
                  <div className={classes.sectionSocksSettingText}>
                    <div><span>With Auth - settings for most of browsers.</span></div>
                    <div><span>No Auth - settings for Chrome.</span></div>
                  </div>
                </div>
                <Divider />
                <TabContext value={selectedTab}>
	                <Tabs value={selectedTab} className={classes.tabContainer} onChange={(_, v) => setSelectedTab(v)} variant='fullWidth'>
		                <Tab classes={tabClasses} label="WITH AUTH" value="0"></Tab>
		                <Tab classes={tabClasses} label="NO AUTH" value="1"></Tab>
	                </Tabs>
	                <TabPanel className={classes.tabPanel} value="0">
		                <div className={classes.sectionSocks}>
		                    <div className={classes.field}>
		                        <CopyButton value={lease.socks.user} />
		                        <span className={classes.label}>Username:</span>
		                        <span className={classes.value}>{lease.socks.user}</span>
		                    </div>
		                    <div className={classes.field}>
		                        <CopyButton value={lease.socks.pwd} />
		                        <span className={classes.label}>Password:</span>
		                        <span className={classes.value}>
		                            {passwordHidden ? '•'.repeat(10) : lease.socks.pwd}
		                            {hideShowPasswordIcon}
		                        </span>
		                    </div>
		                    <div className={classes.field}>
		                        <CopyButton value={lease.socks.ip} />
		                        <span className={classes.label}>SOCKS IP:</span>
		                        <span className={classes.value}>{lease.socks.ip}</span>
		                    </div>
		                    <div className={classes.field}>
		                        <CopyButton value={lease.socks.port} />
		                        <span className={classes.label}>SOCKS Port:</span>
		                        <span className={classes.value}>{lease.socks.port}, 2080, 3080, 4080, 5080</span>
		                    </div>
		                    <div className={classes.link}>
		                        <CopyConnectionStringLink lease={lease} />
		                    </div>

		                </div>
	                </TabPanel>
	                <TabPanel className={classes.tabPanel} value="1">
	                	<PortNoAuth proxy={lease}/>
	                </TabPanel>
                </TabContext>
                <Divider />
                {(!infoChanged(lease) && lease.info.host) && <div className={classes.section}>
                    <div className={classes.field}>
                        <span className={classes.label}>Hostname:</span>
                        <span className={classes.value}>{lease.info.host}</span>
                    </div>
                </div>}
                {infoChanged(lease) &&
                <div className={classes.section}>
                    <div className={`${classes.field} ${classes.header}`}>
                        <InfoIcon className={classes.infoChangeIcon} />
                        Changed properties:
                    </div>
                    <div className={classes.spacer} />
                    {changedFields(lease).map(changedField =>
                        <div key={changedField[0]} className={classes.field}>
                            <span className={classes.label}>{changedField[0]}:</span>
                            <span className={classes.value}>{changedField[1]}</span>
                        </div>
                    )}
                </div>
                }
                <Divider />
                <div className={classes.section}>
                    <div className={classes.field}>Uptime: {humanizedAgeSinceFirstSeen(lease.currentInfo.firstSeen)}</div>
                    <div className={classes.field}>Activity: {humanizedActivity(lease.currentInfo.actv, lease.currentInfo.probeActv)}</div>
                    <div className={classes.spacer} />
                    {showExtraInfo && <>
                        <ProxyDetails
                            proxy={lease.currentInfo}
                            proxyId={lease.agentId}
                            scoreCheck={lease.currentInfo.scoreCheck}
                            speedCheck={lease.currentInfo.speedCheck}
                            static={lease.static} />
                    </>}
                    <div className={classes.link}>
                        <Link href="#" onClick={() => setShowExtraInfo(!showExtraInfo)}>Show {showExtraInfo ? 'less' : 'more'}</Link>
                    </div>
                </div>
                <Divider />
                <div className={classes.sectionBase}>
                    <LeaseStats lease={lease} />
                </div>
            </div>

            <div className={classes.actions}>
                {lease.renewable === true &&
                <Button
                    variant='contained' color='primary' onClick={handleRenew} className={classes.actionButton}>
                    Renew Now
                </Button>
                }
                {!lease.renewable && lease.static !== true && <ButtonWithTooltip
                    tooltipText="You can only renew in the last 5 hours of the lease"
                    variant='contained' color='primary' disabled={true} className={classes.actionButton}>
                    Renew Now
                </ButtonWithTooltip>}
                {(lease.stats?.refundable || (lease.static === true && staticRefund === true)) && <RefundButton lease={lease} props={{variant: 'outlined', color:'primary', className: classes.actionButton}} />}
                {!lease.stats?.refundable && lease.static !== true && 
                <ButtonWithTooltip
                    tooltipText="You can only refund in the first 4 hours of the lease, if the proxy goes offline"
                    variant='outlined' color='primary' disabled={true} className={classes.actionButton}>
                    Refund
                </ButtonWithTooltip>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    lease: state.activeLeases.selected,
})

export default connect(mapStateToProps)(MyProxySidebar)
