import { useAuth0 } from "../redux/auth"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, SnackbarContent } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { actResetPassword, actResetResetPasswordState, RESET_PASSWORD_STATE_FAILED, RESET_PASSWORD_STATE_SUCCESS } from '../redux/backend/auth'
import { useDialogStyles } from './dialog'


const ResetPasswordDialog = (props) => {
    const { user } = useAuth0()
    const classes = useDialogStyles()

    const handleResetPassword = () => {
        props.onClose()

        props.dispatch(actResetPassword(props.user))
    }

    const handleCloseSnackbar = () => {
        props.dispatch(actResetResetPasswordState())
    }

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} className={classes.dialog}>
                <DialogTitle>Change your password?</DialogTitle>
                <DialogContent className={classes.content}>
                    An email will be sent to <strong>{props.user.email}</strong> with the instructions.
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={props.onClose} color='primary'>Cancel</Button>
                    <Button onClick={handleResetPassword} variant='contained' color='primary'>Change Password</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={props.state === RESET_PASSWORD_STATE_SUCCESS} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <SnackbarContent
                    message="Password change email has been sent"
                    action={<Button size='small' color='secondary' onClick={handleCloseSnackbar}>Dismiss</Button>} 
                />
            </Snackbar>
            <Snackbar open={props.state === RESET_PASSWORD_STATE_FAILED} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <SnackbarContent
                    message="Password change failed, please try again or contact support"
                    action={<Button size='small' color='secondary' onClick={handleCloseSnackbar}>Dismiss</Button>} 
                />
            </Snackbar>
        </>
    )
}

const mapStateToProps = (state) => ({
    state: state.resetPasswordState,
})

export default connect(mapStateToProps)(ResetPasswordDialog)