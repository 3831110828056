import { useAuth0 } from "../../redux/auth"
import { Button, makeStyles, TextField , Tabs, Tab, Box, Select, MenuItem, FormControl} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bottomBorder, pageTitle } from '../../styles/common'
import { headerStyle } from '../Shop'
import jsonwebtoken from 'jsonwebtoken'
// import { AUDIENCE } from '../../redux/backend/api-client'
import { actLoadUserInfo, actUpdateUserInfo, actGetLeaseInfo, actGetTransactionInfo, 
    actGetUserPayments, actGetUserInfoByEmail, actBlockUser, actChangeUserPassword } from '../../redux/backend/admin'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import LeaseInfo from './LeaseInfo'
import TransactionInfo from './TransactionInfo'
import PaymentInfo from './PaymentInfo'
import { MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import UserInfoTable from './UserInfoTable'

const useStyles = makeStyles((theme) => ({
    page: {
    },
    header: {
        ...headerStyle(theme),
        ...bottomBorder(theme),
    },
    title: {
        ...pageTitle,
    },
    content: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
    },
    cat: {
        paddingLeft: 200,
        height: 200
    },
    controls: {
        flexGrow: 1,
        display: 'flex'
    },
    row: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: theme.spacing(3),
    },
    rowItem: {
        marginRight: theme.spacing(2),
    },
    rowLabel: {
        marginRight: theme.spacing(2),
        minWidth: 200,
    },
    userIdField: {
        width: '25em',
    },
    info: {
        fontFamily: 'monospace',
        whiteSpace: 'pre',
    },
    datePicker: {
        marginRight: theme.spacing(2),
    },
}))

const AdminPage = (props) => {
    const classes = useStyles()
    const [allowed, setAllowed] = useState(false)
    const [userId, setUserId] = useState(props.userId)
    const [userGetInfoEmail, setUserGetInfoEmail] = useState("")
    const [leaseId,setLeaseId] = useState("")
    const [amountC, setAmountC] = useState(100)

    //transactionInfo fields
    const [transactionUserId, setTransactionUserId] = useState(null)
    const [invoiceId, setInvoiceId] = useState(null)
    const [userEmail, setUserEmail] = useState(null)
    const [addressWallet, setAddressWallet] = useState(null)
    const [transactionId, setTransactionId] = useState(null)
    const [cryptoType, setCryptoType] = useState(null)

    const [firstDate, setFirstDateValue] = useState(new Date())
    const [lastDate, setLastDateValue] = useState(new Date())

    const [newUserPassword, setUserPassword] = useState('')

    const handleTransactionUserIdChange = (e) => {
        setTransactionUserId(e.target.value===""?null:e.target.value)
    }

    const handleInvoiceIdChange = (e) => {
        setInvoiceId(e.target.value===""?null:e.target.value)
    }

    const handleUserEmailChange = (e) => {
        setUserEmail(e.target.value===""?null:e.target.value)
    }

    const handleAddressWalletChange = (e) => {
        setAddressWallet(e.target.value===""?null:e.target.value)
    }

    const handleTransactionIdChange = (e) => {
        setTransactionId(e.target.value===""?null:e.target.value)
    }

    const changeCurrencyType = (e) => {
      setCryptoType(e.target.value===""?null:e.target.value)
    }

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        const check = async () => {
            const token = await getAccessTokenSilently()
            const decoded = jsonwebtoken.decode(token)
            // console.log("decoded")
            // console.log(decoded)

            if (decoded.permissions.includes("read:users") && decoded.permissions.includes("update:accounts")) {
                setAllowed(true)
            }
        }
        check()
    }, [getAccessTokenSilently, setAllowed])

    const handleUserIdChange = (e) => {
        setUserId(e.target.value)
    }

    const handleUserGetInfoEmailChange = (e) => {
        setUserGetInfoEmail(e.target.value)
    }

    const handleAmountChange = (e) => {
        setAmountC(e.target.value)
    }
    const handleShowInfo = () => {
        props.dispatch(actLoadUserInfo(getAccessTokenSilently, userId))
    }
    const handleAddDepositPermission = () => {
        props.dispatch(actUpdateUserInfo(getAccessTokenSilently, userId, { permissions: { add: ["DEPOSIT"] } }))
    }
    const handleRevokeDepositPermission = () => {
        props.dispatch(actUpdateUserInfo(getAccessTokenSilently, userId, { permissions: { remove: ["DEPOSIT"] } }))
    }
    const handleUpdateBalance = () => {
        const amountCInt = parseInt(amountC)
        if (amountCInt) {
            props.dispatch(actUpdateUserInfo(getAccessTokenSilently, userId, { balanceDeltaC: amountCInt }))
        }
    }

    const handleLeaseIdChange = (e) => {
        let regEx = new RegExp(/\w{4}-\d{4}/g)
        const res = e.target.value.match(regEx)
        if (res !== null  && typeof res[0] !== 'undefined'){
          setLeaseId(res[0])
        } else {
          setLeaseId(e.target.value)
        }
        // console.log(res)
    }

    const handleShowLeaseInfo = () => {
        props.dispatch(actGetLeaseInfo(getAccessTokenSilently, leaseId))
    }

    const handleShowTransactionInfo = () => {
        let data = {}
        let tmp = null

        transactionUserId != null ? data['user_id'] = transactionUserId : tmp = null
        invoiceId != null ? data['uid'] = invoiceId : tmp = null
        userEmail != null ? data['user_email'] = userEmail : tmp = null
        addressWallet != null ? data['address'] = addressWallet : tmp = null
        transactionId != null ? data['txid'] = transactionId : tmp = null
        cryptoType != null ? data['type'] = cryptoType : tmp = null

        // console.log('DATA')
        // console.log(data)
        props.dispatch(actGetTransactionInfo(getAccessTokenSilently, data))
    }

    const [value, setValue] = React.useState('0');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    if (!allowed) {
        return <div> ACCESS DENIED :-(</div>
    }

    const found = props.userInfo ? true : false

    const handleKeypress = e => {
      // console.log(e.keyCode)
      // console.log(e.key)
       if (e.key == 'Enter') {
         handleShowLeaseInfo();
       }
     }

    const datePickerLabelFunc = (prefix) => { return (date) => prefix + ' ' + moment(date).format('YYYY-MM-DD') }
    
    const datePickerProps = {
        className: classes.datePicker,
        inputVariant: 'standard',
        variant: 'inline',
        disableFuture: true,
        autoOk: true,
    }

    const setFirstDate = (date) => {
        let formattedDate = moment(date).format('YYYY-MM-DD');
        setFirstDateValue(formattedDate)
    }
    const setLastDate = (date) => {
        let formattedDate = moment(date).format('YYYY-MM-DD');
        setLastDateValue(formattedDate)
    }

    const handleShowUserPayments = () => {
        props.dispatch(actGetUserPayments(getAccessTokenSilently, 0, 1000, firstDate, lastDate, userId))
     }

    const handleGetUserInfo = () => {
        props.dispatch(actGetUserInfoByEmail(getAccessTokenSilently, userGetInfoEmail))
    }

    const handleChangeUserPassword = () => {
        props.dispatch(actChangeUserPassword(getAccessTokenSilently, userGetInfoEmail, newUserPassword))
    }

    const handleBlockUser = () => {
        props.dispatch(actBlockUser(getAccessTokenSilently, userGetInfoEmail))
    }

    // console.log('PROPS')
    // console.log(props)
    return (
            <div className={classes.page}>
                <div className={classes.header}>
                    <div className={classes.title}>Admin Page</div>
                </div>
                <div className={classes.content}>
                <Box>
                    <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab label="User Info" value="0" />
                            <Tab label="User Balance" value="1" />
                            <Tab label="Lease Info" value="2" />
                            <Tab label="Transaction Info" value="3" />
                            <Tab label="User Payments" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="0">

                    <div className={classes.controls}>
                    <div>
                        <div className={classes.row}>
                            <div className={classes.rowLabel}>Find User:</div>
                            <TextField
                                className={`${classes.rowItem} ${classes.userIdField}`}
                                label="user email"
                                value={userGetInfoEmail} onChange={handleUserGetInfoEmailChange}/>
                            <Button
                                className={classes.rowItem}
                                variant='contained'
                                color='primary'
                                onClick={handleGetUserInfo}>
                                    Show Info
                            </Button>
                            {(props.userData !== null && props.userData !== undefined) && (
                                <Button
                                    className={classes.rowItem}
                                    variant='contained'
                                    color='primary'
                                    onClick={handleBlockUser}>
                                      {props?.userData?.blocked === false || !props?.userData?.blocked ? 'Block user' : 'Unblock user'}  
                                </Button>
                            )}
                        </div>
                        <div className={classes.row}>
                        {(props.userData !== null && props.userData !== undefined) && (
                            <div className={classes.info}>
                                 <UserInfoTable userData={props.userData} handleChangeUserPassword={handleChangeUserPassword} setUserPassword={setUserPassword} newpass={newUserPassword}/>
                                {/* {props.userData !== null ? JSON.stringify(props.userData, null, 2) : props.userDataError} */}
                            </div>
                        )}
                        </div>
                        
                    </div>
                    <div className={classes.cat}>
                        <img src="https://cataas.com/cat/says/hello%20admin?width=400" alt="cat" />
                    </div>
                    </div>
                    </TabPanel>
                    <TabPanel value="1">

                    <div className={classes.controls}>
                    <div>
                        <div className={classes.row}>
                            <div className={classes.rowLabel}>Find User:</div>
                            <TextField
                                className={`${classes.rowItem} ${classes.userIdField}`}
                                label="auth0 user ID"
                                value={userId} onChange={handleUserIdChange}/>
                            <Button
                                className={classes.rowItem}
                                variant='contained'
                                color='primary'
                                onClick={handleShowInfo}>
                                    Show Info
                            </Button>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.info}>{props.userInfo !== null ? JSON.stringify(props.userInfo, null, 2) : "not found"}</div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.rowLabel}>Deposit Permission:</div>
                            <Button
                                onClick={handleAddDepositPermission}
                                disabled={!found}
                                className={classes.rowItem}
                                variant='contained'
                                color='primary'>Allow DEPOSIT</Button>
                            <Button
                                onClick={handleRevokeDepositPermission}
                                disabled={!found}
                                className={classes.rowItem}
                                color='secondary'>Revoke DEPOSIT</Button>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.rowLabel}>Balance Update:</div>
                            <TextField
                                className={classes.rowItem}
                                label="cents"
                                size="small"
                                value={amountC} onChange={handleAmountChange} />
                            <Button
                                onClick={handleUpdateBalance}
                                disabled={!found}
                                className={classes.rowItem}
                                variant='contained'
                                color='primary'>Apply to balance</Button>
                        </div>
                    </div>
                    <div className={classes.cat}>
                        <img src="https://cataas.com/cat/says/hello%20admin?width=400" alt="cat" />
                    </div>
                    </div>
                    </TabPanel>

                    <TabPanel value="2">
                        <div className={classes.controls}>
                        <div>
                            <div className={classes.row}>
                                <div className={classes.rowLabel}>Find Lease:</div>
                                <TextField
                                    className={`${classes.rowItem} ${classes.userIdField}`}
                                    label="Lease ID"
                                    value={leaseId} onChange={handleLeaseIdChange}  onKeyPress={handleKeypress} />
                                <Button
                                    className={classes.rowItem}
                                    variant='contained'
                                    color='primary'
                                    onClick={handleShowLeaseInfo}>
                                        Show Info
                                </Button>
                            </div>
                            <LeaseInfo lease={props}/>
                        </div>
                        <div className={classes.cat}>
                            <img src="https://cataas.com/cat/says/hello%20admin?width=400" alt="cat" />
                        </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="3">
                    <div className={classes.controls}>
                    <div>
                        <div className={classes.row}>
                            <div className={classes.rowLabel}>Find Transaction:</div>
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={`${classes.rowItem} ${classes.userIdField}`}
                                label="User ID"
                                value={transactionUserId}
                                onChange={handleTransactionUserIdChange}/>
                        </div>
                        <div className={classes.row}>
                        <TextField
                            className={`${classes.rowItem} ${classes.userIdField}`}
                            label="User Email"
                            value={userEmail}
                            onChange={handleUserEmailChange}/>
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={`${classes.rowItem} ${classes.userIdField}`}
                                label="Invoice ID"
                                value={invoiceId}
                                onChange={handleInvoiceIdChange}/>
                            </div>

                            <div className={classes.row}>
                            <TextField
                                className={`${classes.rowItem} ${classes.userIdField}`}
                                label="Wallet Address sent to"
                                value={addressWallet}
                                onChange={handleAddressWalletChange}/>
                            </div>
                            <div className={classes.row}>
                            <TextField
                                className={`${classes.rowItem} ${classes.userIdField}`}
                                label="Transaction ID"
                                value={transactionId} onChange={handleTransactionIdChange}/>
                            </div>
                            <div className={classes.row}>
                            <FormControl>
                            <Select style={{width: 180}} label='Currency' onChange={changeCurrencyType} value={cryptoType}>
                                <MenuItem value='btc'>BTC</MenuItem>
                                <MenuItem value='ltc'>LTC</MenuItem>
                            </Select>
                            </FormControl>
                            </div>
                            <div className={classes.row}>
                            <Button
                                className={classes.rowItem}
                                variant='contained'
                                color='primary'
                                onClick={handleShowTransactionInfo}>
                                    Show Info
                            </Button>
                            </div>
                            <div className={classes.row}>
                                <TransactionInfo data={props}/>
                            </div>
                        </div>
                        <div className={classes.cat}>
                            <img src="https://cataas.com/cat/says/hello%20admin?width=400" alt="cat" />
                        </div>
                    </div>
                    </TabPanel>
                    <TabPanel value="4">
                        <div className={classes.controls}>
                        <div>
                            <div className={classes.row}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker 
                                            {...datePickerProps} 
                                            onChange={setFirstDate} 
                                            labelFunc={datePickerLabelFunc('First date:')} 
                                            value={firstDate} 
                                        />
                                        <DatePicker 
                                            {...datePickerProps} 
                                            onChange={setLastDate} 
                                            labelFunc={datePickerLabelFunc('Last date:')} 
                                            value={lastDate} 
                                        />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.row}>
                                <TextField
                                    className={`${classes.rowItem} ${classes.userIdField}`}
                                    label="auth0 user ID"
                                    value={userId} onChange={handleUserIdChange}/>
                                <Button
                                    className={classes.rowItem}
                                    variant='contained'
                                    color='primary'
                                    onClick={handleShowUserPayments}>
                                    Show Payments
                                </Button>
                            </div>
                            <div className={classes.row}>
                                <PaymentInfo data={props}/>
                            </div>
                        </div>
                        </div>
                    </TabPanel>
                </TabContext>
                </Box>


                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.admin,
})

export default connect(mapStateToProps)(AdminPage)
