import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Link } from '@material-ui/core'
import { useDialogStyles } from './LeaseFlow'
import { actConfirmLease } from '../../redux/backend/lease'
import { useAuth0 } from "../../redux/auth"
import { openTermsAndConditions } from '../../util/util'

const LeaseTestFailed = (props) => {
    const dialogClasses = useDialogStyles()
    const {getAccessTokenSilently} = useAuth0()

    const handleBuyClick = () => {
        props.dispatch(actConfirmLease(getAccessTokenSilently, true))
    }

    return (
        <Dialog 
            classes={{paper: dialogClasses.dialog}} 
            open={true} 
            onClose={props.onClose} 
            disableBackdropClick={true}
            transitionDuration={0}
        >
            <DialogTitle>The proxy is not responding</DialogTitle>
            <DialogContent className={dialogClasses.content}>
                A proxy may temporarily lose connectivity and then be back online later. <br />
                You can still buy it, and if it does not work, you may request a full refund. <br /><br />
                See our <Link href="#" onClick={openTermsAndConditions}>Terms and Conditions</Link> for details.
            </DialogContent>
            <DialogActions classes={{root: dialogClasses.actions}}>
                <Button id='cancel_button' variant='text' color='primary' onClick={props.onClose}>Cancel</Button>
                <Button id='confirm_no_test_button' variant='contained' disableElevation color='primary' onClick={handleBuyClick}>Buy Proxy</Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(LeaseTestFailed)