/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Box, Typography, makeStyles, TablePagination, Link, TextField } from '@material-ui/core';
import { pageTitle, bottomBorder, topBorder } from '../../styles/common';
import { headerStyle } from '../Shop';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import PaymentTable from './PaymentTable';
import { actChangeTxRecordPage, actSetFirstTxRecordDate, actSetLastTxRecordDate } from '../../redux/backend/payments';
import { useAuth0 } from "../../redux/auth"
import { actOpenAddFunds } from '../../redux/backend/add-funds';
import PaymentItemMobile from './PaymentItemMobile';
import { formatMoney } from '../../util/money';
import ClipLoader from "react-spinners/ClipLoader";
import { useEffect } from 'react';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import './payments.css';

const useStyles = makeStyles((theme) => ({
    tab: {
        height: '100%',
        width: '100%',

        display: 'grid',
        gridTemplateRows: 'auto auto 1fr auto',
        gridTemplateColumns: '1fr',
    },
    header: {
        ...headerStyle(theme),

        gridRowStart: 1,
        gridRowEnd: 2,
        gridColumnStart: 1,
        gridColumnEnd: 2,

        display: 'flex',
        alignItems: 'center',
    },
    title: {
        ...pageTitle,

        gridRowStart: 2,
        gridRowEnd: 3,
        gridColumnStart: 1,
        gridColumnEnd: 2,

        marginRight: theme.spacing(3),
    },
    filters: {
        ...bottomBorder(theme),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        display: 'flex'
    },
    datePicker: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        gridRowStart: 3,
        gridRowEnd: 4,
        gridColumnStart: 1,
        gridColumnEnd: 2,
        overflowY: 'scroll',
    },
    footer: {
        gridRowStart: 4,
        gridRowEnd: 5,
        gridColumnStart: 1,
        gridColumnEnd: 2,

        ...topBorder(theme),
    },
    paginationCaption: {
        fontWeight: 500,
    },
    paginationSpacer: {
        display: 'none',
    },
}));

const PaymentPage = (props) => {

    const classes = useStyles();

    let startDate = new Date(props.firstDate.format('YYYY-MM-DD'));
    let endDate = new Date(props.lastDate.format('YYYY-MM-DD'));
    let difference = endDate.getTime() - startDate.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    const datePickerLabelFunc = (prefix) => { return (date) => prefix + ' ' + moment(date).format('YYYY-MM-DD'); };

    const datePickerProps = {
        className: classes.datePicker,
        inputVariant: 'standard',
        variant: 'inline',
        disableFuture: true,
        autoOk: true,
        minDate: props.minDate,
    };

    // const daysArray = () => {
    //     console.log(totalDays, "in daysArray", startDate);
    //     const tomorrow = new Date(startDate);
    //     const dateArray = [];
    //     for (let i = 0; i <= totalDays; i++) {
    //         let next_date = tomorrow.setDate(tomorrow.getDate() + 1);
    //         console.log(new Date(next_date),'next_date');
    //         dateArray.push({ date: new Date(next_date) });
    //         console.log(dateArray, 'dateArray');
    //     }
    //     console.log(dateArray, 'dateArraydateArraydateArray');
    //     return dateArray;
    // };

    const { getAccessTokenSilently } = useAuth0();

    const handlePageChange = (_, page) => {
        props.dispatch(actChangeTxRecordPage(getAccessTokenSilently, page));
    };

    const setFirstDate = (date) => {
        props.dispatch(actSetFirstTxRecordDate(getAccessTokenSilently, date));
    };
    
    const setLastDate = (date) => {
        props.dispatch(actSetLastTxRecordDate(getAccessTokenSilently, date));
    };

    const showAddFunds = () => {
        props.dispatch(actOpenAddFunds());
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActiveItem('Payments'));
        console.log('here')
    }, []);

    const [offset, setOffset] = useState(0);
    const isMobile = true;

    const showMorePayments = (page) => {
        props.dispatch(actChangeTxRecordPage(getAccessTokenSilently, page, isMobile));
    };

    // console.log(props, 'length ... total')

    return (
        <>
            <div className='payment-page'>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Box className={classes.tab}>
                        <Box className={classes.header}>
                            <Typography className={classes.title}>Payments</Typography>
                            <Link onClick={showAddFunds} href='#' style={{marginTop: '60px'}}>Add Funds</Link>
                        </Box>
                        <Box className={classes.filters}>
                            <div className='descktop-start-data-picker'>
                                <DatePicker
                                    {...datePickerProps}
                                    onChange={setFirstDate}
                                    labelFunc={datePickerLabelFunc('First date:')}
                                    value={props.firstDate}
                                />
                            </div>
                            <div className='descktop-end-data-picker'>
                                <DatePicker
                                    {...datePickerProps}
                                    onChange={setLastDate}
                                    labelFunc={datePickerLabelFunc('Last date:')}
                                    value={props.lastDate}
                                />
                            </div>
                        </Box>
                        <Box className={classes.tableContainer}>
                            <div className='payment-table'>
                                <PaymentTable />
                            </div>
                        </Box>
                        <div className='table-pagination'>
                            <Box className={classes.footer}>
                                <TablePagination
                                    component='div'
                                    classes={{ root: classes.paginationRoot, spacer: classes.paginationSpacer, caption: classes.paginationCaption }}
                                    rowsPerPage={props.rowsPerPage}
                                    rowsPerPageOptions={[]}
                                    count={props.total}
                                    page={props.page}
                                    onChangePage={handlePageChange}
                                />
                            </Box>
                        </div>
                    </Box>
                </MuiPickersUtilsProvider>
            </div>
            <div className='payment-mobile'>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Box className={classes.tab}>
                        <div className='data-picker-container'>
                            <div className='mobile-start-data-picker'>
                                <DatePicker
                                    label="Start Date"
                                    {...datePickerProps}
                                    onChange={setFirstDate}
                                    labelFunc={datePickerLabelFunc('')}
                                    value={props.firstDate}
                                />
                            </div>
                            <div className='mobile-end-data-picker'>
                                <DatePicker
                                    label="End Date"
                                    {...datePickerProps}
                                    onChange={setLastDate}
                                    labelFunc={datePickerLabelFunc('')}
                                    value={props.lastDate}
                                />
                            </div>
                        </div>
                        <div className='payment-mobile'>
                            {
                                // daysArray()?.map((day, day_i) => {
                                // console.log(day,'dayyyyrrrrrrrrrrrrrrr');
                                //     return <div key={day_i}>
                                //         {
                                            props?.txRecords
                                            // ?.filter((txR) => {
                                            //     console.log(new Date(txR.time).getMonth(),'rrrrrrrrrrrrrrrrr',day.date.getMonth());
                                            //     return ( new Date(txR.time).getMonth()+1 === day.date.getMonth()+1)
                                            // })                                           
                                            .map((item, index) => {
                                                return <>
                                                    {/* <sapn className='current-date'>{moment(day?.date).format('YYYY DD MMMM')}</sapn> */}
                                                    <PaymentItemMobile
                                                        key={index}
                                                        title={item?.id}
                                                        description={item?.memo}
                                                        amount={formatMoney(item?.amtC)}
                                                        balance={item?.balC ? formatMoney(item?.balC) : ''}
                                                    />
                                                </>
                                            })}
                                    </div>
                                {/* })
                            } */}
                             {
                                props?.txRecords && props?.txRecords?.length > 0 && props?.loading &&
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <ClipLoader
                                        color='#1589EE'
                                        size={30}
                                        className='cliploader shop'
                                    />
                                </div>
                            }
                            {
                                props?.txRecords?.length < props?.total && <div
                                    className='show-more-payments-btn'
                                    onClick={() => {
                                        setOffset(offset + 1);
                                        showMorePayments(offset + 1);
                                    }}>Show more payments</div>
                            } 
                        {/* </div> */}
                    </Box>
                </MuiPickersUtilsProvider>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.payments,
});

export default connect(mapStateToProps)(PaymentPage);